import React from 'react';
import { withRouter } from 'react-router-dom';
import '@/assets/css/swiper.css';
import style from './aboutUs.module.scss';
import StaticChineseVersion from '../chineseVersion/chineseVersion';
import StaticEngliseVersuion from '../engliseVersuion/engliseVersuion';
import StaticBigStory from '../bigStory/bigStory';

class staticAboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFlag: 1,
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {}

  toActive(labe) {
    this.setState({
      activeFlag: labe,
    });
  }

  render() {
    const { activeFlag } = this.state;
    return (
      <div className={style.container}>
        <div className={style.labelYe}>
          <div
            className={`${activeFlag === 1 ? style.active : ''}`}
            role="button"
            tabIndex={activeFlag}
            onClick={this.toActive.bind(this, 1)}
          >
            中文版
          </div>
          <div
            className={`${activeFlag === 2 ? style.active : ''}`}
            role="button"
            tabIndex={activeFlag}
            onClick={this.toActive.bind(this, 2)}
          >
            English
          </div>
          <div
            className={`${activeFlag === 3 ? style.active : ''}`}
            role="button"
            tabIndex={activeFlag}
            onClick={this.toActive.bind(this, 3)}
          >
            大记事
          </div>
        </div>
        {
          activeFlag && activeFlag === 1 && <StaticChineseVersion />
        }
        {
          activeFlag && activeFlag === 2 && <StaticEngliseVersuion />
        }
        {
          activeFlag && activeFlag === 3 && <StaticBigStory />
        }
      </div>
    );
  }
}

export default withRouter(staticAboutUs);
