import React from 'react';
import { withRouter } from 'react-router-dom';
import style from './header.module.scss';
import setImg from '@/utils/imgs';

class staticHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lazyLoadImg: setImg('lazyload.png'), // 默认图
      activeFlag: 0,
      aboutUsFlag: false,
      partFlag: false,
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
  }

  // 关于我们鼠标移入
  aboutUsMouseEnter = () => {
    this.state.aboutUsFlag = true
    this.setState({});
  };

  // 关于我们鼠标移除
  aboutUsMouseLeave = () => {
    this.setState({
      aboutUsFlag: false,
    });
  };
  // 合作关系标移入
  partMouseEnter = () => {
    this.state.partFlag = true
    this.setState({});
  };

  // 合作关系鼠标移除
  partMouseLeave = () => {
    this.setState({
      partFlag: false,
    });
  };

  toActive(labe, child) {
    this.state.activeFlag = labe;
    this.setState({});
    const { history } = { ...this.props };
    if (labe === 0 && child === 0) {
      history.push({ pathname: '/' });
    } else if (labe === 1 && child === 1) {
      history.push({ pathname: '/Partnership' });
    } else if (labe === 1 && child === 2) {
      history.push({ pathname: '/PCStaticBrand' });
    } else if (labe === 1 && child === 3) { 
      history.push({ pathname: '/business' });
    }  else if (labe === 2 && child === 0) {
      history.push({ pathname: '/PCStaticMediaDateBase' });
    } else if (labe === 3 && child === 0) {
      history.push({ pathname: '/newsInformation' });
    } else if (labe === 4 && child === 1) {
      history.push({ pathname: '/PCStaticAboutUs' });
    } else if (labe === 4 && child === 2) {
      history.push({ pathname: '/Responsibility' });
    } else if (labe === 5 && child === 0) {
      history.push({ pathname: '/InvoiceQuery' });
    }
  }

  render() {
    const { lazyLoadImg, activeFlag, aboutUsFlag, partFlag } = this.state;
    return (
      <div className={style.container}>
        <div className={style.header}>
          <img className={style.headerLogo} src={setImg('logo.png') || lazyLoadImg} alt="微信扫码二维码" />
          <div className={style.label}>
            <div
              className={`${activeFlag === 0 ? style.active : ''}`}
              role="button"
              tabIndex={activeFlag}
              onClick={this.toActive.bind(this, 0, 0)}
            >
              首页
            </div>
            <div
              className={`${activeFlag === 1 ? style.active : ''}`}
              role="button"
              tabIndex={activeFlag}
              onMouseEnter={this.partMouseEnter}
            >
              合作关系
              <img className={style.iconDown} src={setImg('icon_down.png') || lazyLoadImg} alt="展开" />
              {
                partFlag && (
                <div 
                  className={style.aboutUsLabel}
                  onMouseLeave={this.partMouseLeave}
                >
                  <div>合作关系<img className={style.iconDown} src={setImg('icon_down.png') || lazyLoadImg} alt="展开" /></div>
                  <div
                    role="button"
                    tabIndex={activeFlag}
                    onClick={this.toActive.bind(this, 1, 1)}
                  >
                    成为供应商
                  </div>
                  <div
                    role="button"
                    tabIndex={activeFlag}
                    onClick={this.toActive.bind(this, 1, 2)}
                  >
                    合作品牌
                  </div>
                  <div
                    role="button"
                    tabIndex={activeFlag}
                    onClick={this.toActive.bind(this, 1, 3)}
                  >
                    商务合作
                  </div>
                </div>
                )
              }
            </div>
            <div
              className={`${activeFlag === 2 ? style.active : ''}`}
              role="button"
              tabIndex={activeFlag}
              onClick={this.toActive.bind(this, 2, 0)}
            >
              媒体资料库
            </div>
            <div
              className={`${activeFlag === 3 ? style.active : ''}`}
              role="button"
              tabIndex={activeFlag}
              onClick={this.toActive.bind(this, 3, 0)}
            >
              新闻动态
            </div>
            <div
              className={`${activeFlag === 4 ? style.active : ''}`}
              role="button"
              tabIndex={activeFlag}
              onMouseEnter={this.aboutUsMouseEnter}
            >
              关于我们
              <img className={style.iconDown} src={setImg('icon_down.png') || lazyLoadImg} alt="展开" />
              {
                aboutUsFlag && (
                <div 
                  className={style.aboutUsLabel}
                  onMouseLeave={this.aboutUsMouseLeave}
                >
                  <div>关于我们<img className={style.iconDown} src={setImg('icon_down.png') || lazyLoadImg} alt="展开" /></div>
                  <div
                    role="button"
                    tabIndex={activeFlag}
                    onClick={this.toActive.bind(this, 4, 1)}
                  >
                    公司介绍
                  </div>
                  <div
                    role="button"
                    tabIndex={activeFlag}
                    onClick={this.toActive.bind(this, 4, 2)}
                  >
                    社会责任
                  </div>
                </div>
                )
              }
            </div>
            <div
              className={`${activeFlag === 5 ? style.active : ''}`}
              role="button"
              tabIndex={activeFlag}
              onClick={this.toActive.bind(this, 5, 0)}
            >
              电子发票查询
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(staticHeader);
