import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import routes from '../router';
// import Header from '../components/header';
// import Footer from '../components/footer';
import StaticHeader from '../components/PCStatic/header/header';
import StaticFooter from '../components/PCStatic/footer/footer';

function App() {
  const reg = /Android|webOS|iPhone|iPod|BlackBerry/i;
  const flag = reg.test(navigator.userAgent);
  const href = window.location.href;
  const hrefFP = href.indexOf('InvoiceQuery');
  let returnData;
  if (!flag) {
    returnData = (
      <div>
        <StaticHeader />
        <Switch>
          {
            routes.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                render={(props) => (
                  <route.component {...props} routes={route.routes} />
                )}
              />
            ))
          }
          <Redirect from="/*" to="/" />
        </Switch>
        <StaticFooter />
      </div>
    );
  } else {
    returnData = (
      <div>
        <Switch>
          {
            routes.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                render={(props) => (
                  <route.component {...props} routes={route.routes} />
                )}
              />
            ))
          }
          <Redirect from="/*" to="/" />
        </Switch>
      </div>
    );
  }

  return (
    <Router>
      {returnData}
    </Router>
  );
}

export default App;
