import React from 'react';
import { withRouter } from 'react-router-dom';
import '@/assets/css/swiper.css';
import style from './bigStory.module.scss';

class staticBigStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {}

  render() {
    return (
      <div className={style.bigStorycontainer}>
        <div className={style.title}>2021年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>元月9日，聚鲨环球精选2021年年度大会在北京总部隆重召开。会议确定了2021年公司年度主题仍延续“重实效、看结果”。对2020年工作进行总结，部署2021年发展战略和重点工作，表彰2020年度做出突出成绩的聚鲨团队和个人。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>元月17日，《曹颖的鲨鱼对决秀》第四季圆满收官。聚鲨环球精选相关频道持续8个小时播出“鲨鱼对决秀巅峰之战”。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>元月23日，聚鲨环球精选联手老字号推出全聚德阖家团圆家宴礼盒，最中国年夜饭助力防疫留京过年。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>元月27日，聚鲨环球精选成立直播事业部，面对内部员工邀请共同开创具有聚鲨特色的直播事业。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>元月29日，聚鲨环球精选核心人员与融合共生合伙人大会在北京总部举行。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月23日，聚鲨环球精选金牌文化栏目《收藏的故事》更名为《不能错过的珍宝》，全新改版后正式上线。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月3日， 聚鲨环球精选授权省广星美达成为频道年度媒体资源整合营销及广告总代理。后与CCData网联数科达成战略合作。整合营销及广告业务有突破。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月上旬，聚鲨环球精选发布董事长嘉奖令，表彰奖励聚鲨主播揽获腾讯视频直播大赛多项大奖。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月8日，大数据营销事业部长沙二部举行盛大开业典礼。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月19日，大数据营销事业部北京分部五周年庆典隆重举行。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月19日，《鲨鱼对决秀》第五季全新上线。</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>3月23日伦敦奥运会跳水冠军张雁全亲临《不能错过的珍宝》参与第32届奥运会官方徽宝的首发直播。</p></div>
        <div className={style.title}>2020年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月3日， 2019年度英雄大会隆重召开，确立了2020年度公司工作主题延续“重实效，看结果”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月17日，2020聚鲨环球精选年会成功举办</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月10日，董事长孔炯发出《致聚鲨环球精选全体员工的一封信》，号召全体员工为尽快消除冠状病毒疫情影响，尽快推动企业重新回到快速发展的轨道贡献力量。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月10，聚鲨环球精选党支部将募集到的76278.79元人民币善款捐赠武汉抗疫一线。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月13日，聚鲨环球精选向西南边远地区捐赠了价值近30万元的一万服抗疫药物。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月15日，聚鲨环球精选荣获全国产品和服务质量诚信示范单位、全国质量检验稳定合格产品荣誉称号。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月15日，《曹颖的鲨鱼对决秀》第三季成功上线。7月25日，聚鲨环球精选推出《曹颖的鲨鱼对决秀》第三季收官之战——“聚鲨热销榜-年中对决秀”活动。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18日，为庆贺聚鲨环球精选7周年生日，董事长孔炯首次通过视频直播与聚鲨员工进行深度交流。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18日至20日，借聚鲨环球精选7周年庆之际，开展品牌推广与行销活动，推出折扣及送礼等多项优惠，并进行大型户外购物直播节目。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月30日，中国电视购物联盟发出了《关于2019年度中国电视购物行业评优结果通报的通知》。聚鲨环球精选一举揽获模式创新奖、媒体传播力奖、年度公益奖以及优秀节目奖流行美妆类金奖、珠宝收藏类银奖和厨房食品类铜奖等多项大奖。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月16日至6月18日，聚鲨环球精选全面开启《618年中狂欢节》专场活动。三天狂欢节活动期间，聚鲨环球精选各渠道业绩提升明显。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月10日欧洲中部时间晚上8点（北京时间6月11日凌晨2点），在英国伦敦举办的第五届EMMA Awards 2020网上颁奖典礼上，聚鲨环球精选娱乐购物节目《曹颖的鲨鱼对决秀》荣获2020年最佳直播节目奖。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月24日，聚鲨环球精选2020年聚鲨学院S计划正式开营。聚鲨环球精选董事长、聚鲨学院院长孔炯先生开讲S计划第一课——《公牛鲨的修炼》。为期三个月的“ S计划 ”训练营是聚鲨环球精选面向年轻管理者和高潜力人才的核心管理人才培养计划。首期43名学员来自各部门的推荐及公司严格的审核选拔。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月11日上午，聚鲨环球精选召开2020年年中总结大会。聚鲨环球精选董事长兼总裁孔炯先生作了题为《一以贯之，动作不可变形》的年中工作报告；对公司上半年工作进行总结，并对下半年工作进行部署、安排。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月7日下午，聚鲨环球精选举办了一场由董事长孔炯先生与58名公司历届管培生参加的座谈会。董事长与大家就聚鲨企业文化的核心价值观、当今形势下企业发展前景以及管培生们的个人成长与职业规划等内容进行了深入而愉快地交流。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月份，聚鲨环球精选签约易观方舟智能用户运营平台，旨在通过易观方舟的智能分析技术，提升平台产品优化、深度洞察用户行为偏好，通过数据驱动为用户提供更好的消费服务体验。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月11日，聚鲨环球精选在天津市宝坻开发区举行了隆重的新建供应链管理与配送中心项目奠基仪式。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月14日，聚鲨环球精选举办了以“走近会员、了解会员，更好地服务会员”为目的的会员座谈会。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月1日上午，北京新媒体集团与聚鲨环球精选合作签约仪式在北京广播电视台新媒体基地演播厅隆重举行。此次战略合作双方整合自身优势，联手打造的媒体电商新平台——北京IPTV“聚鲨环球精选”电商平台于当日正式上线。并同步启动“千县千品助力乡村振兴计划”和“北京IPTV用户电视购物3亿消费券大放送计划”。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月4日，《曹颖的鲨鱼对决秀》第四季于在聚鲨环球精选频道周末黄金档强势回归。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月18日，中央广播电视总台编务会议成员，中国国际电视总公司党委书记、董事长赵子忠一行莅临聚鲨环球精选总部参观视察。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月24日中午，北京广播电视台党组书记、台长余俊生莅临聚鲨环球精选总部参观视察。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月份，聚鲨环球精选专门为北京消费者提供鲜活、特色食材的特别栏目——《聚鲨鲜生活》和与北京电视台生活频道金牌栏目《美食地图》联手打造的《聚鲨美食地图》先后上线。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月21日，聚鲨环球精选战略合作品牌悦妍时肌&利得盈养新品发布会隆重举行，两大美国新品牌登陆聚鲨。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月21日，聚鲨环球精选大数据营销事业部燕郊中心在位于河北省三河市中心举行了盛大的乔迁开业仪式。聚鲨环球精选版图又扩大。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月23日，中国广播电视网络有限公司副总编辑彭文胜一行莅临聚鲨环球精选总部参观视察。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月28日，中国广播电视网络有限公司总经理梁晓涛一行莅临聚鲨环球精选总部参观视察。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月24日上午，聚鲨环球精选融合共生核心人员会议在北京总部隆重举行。聚鲨环球精选董事长兼CEO孔炯先生要求融合共生团队成员不忘初心，坚守梦想，带领团队共同奋斗，创造聚鲨环球精选更加辉煌的明天。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月25日，聚鲨环球精选MODERN FUN 第八季—— 2020秋冬品牌发布直播在聚鲨环球精选频道开播。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月初，聚鲨环球精选发起“助力延安，香飘中华”公益行动，多渠道联动宣传推广销售陕西省延安地区贫困乡村产出的优质农产品，帮助革命老区人民解决丰收季产品销售难的燃眉之急，也为政府倡导的乡村振兴计划和脱贫攻坚战助力。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月3日，聚鲨环球精选开行业之先河，与拉菲品牌商携手打造“拉菲—聚鲨超级品牌日。”收藏级珍品红酒、传奇的“82年拉菲”登录晚间文化收藏栏目《魏征收藏的故事》。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月9日至11日，聚鲨环球精选2020年终极大促——为期三天的“嗨购双十一”活动，各渠道共达成成交额二亿元，双十一当日成交额突破一亿元，双双创出双十一活动和单日成交额历史最好成绩，交出了一份可喜的成绩单。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月17日，为响应国家“乡村振兴”战略，助力乡村经济发展，聚鲨环球精选联合北京IPTV推出的《千县千品》助农特别栏目，在歌华有线十九频道和北京IPTV聚鲨环球精选频道同时上线。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月5日，聚鲨环球精选独家承制并播出的“首届北京网络直播大赛”总决赛晚会“冠军之夜”在聚鲨环球精选北京总部的演播大厅内，面向全国观众精彩直播，市委、市政府领导应邀亲临直播现场参与总决赛晚会。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月26日，聚鲨环球精选携手北京IPTV、北京联通，多平台联动为北京消费者打造专属的“京城年终购物节”。</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>12月31日至2021年1月1日，聚鲨环球精选举办跨年盛典。</p></div>
        <div className={style.title}>2019年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月2日， 2018年度英雄大会隆重召开，确立了2019年度公司工作主题为“重实效，看结果”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月25日，2019年会开幕</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月8日，大数据南方中心盛大启幕</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月14日，特别栏目——《美丽驾到》成功上线</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月15日，荣膺 “全国产品和服务质量诚信示范企业”奖项；获得“全国质量检验稳定合格产品”证明</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月3日，揽获中国电视购物行业年会优秀节目二金一银一铜奖、优秀制作人奖、优秀主持人奖、模式创新奖、社交媒体力传播奖等多项重磅大奖</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月11日，2019中国电子商务业年会在浙江省义乌隆重召开。会上，聚鲨环球精选当选2018年度通讯营销行业十佳明星企业。聚鲨环球精选副总裁宋子扬发表了题为《在实践中探索中国媒体零售行业的崛起之路》的主题演讲</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月14日，MODERN FUN 第六季——“2019品牌发布”直播启幕</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月17日，欧洲电子零售协会（ERA Europe）官网发表对中国聚鲨环球精选董事长兼总裁、CITRUSS董事长孔炯的专访</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月22日，六周年庆生月启动大会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18日至20日，六周年庆生狂欢</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月12日，全新生活分享节目《丹丹日记》成功首播。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月18日至25日，董事长兼CEO孔炯应邀在MCMS峰会上发表演讲。一行出访欧洲匈牙利、荷兰等国及中东地区，谋求供应链的优化，推动企业国际化进程再迈新步伐。并去往中东，拜访了中国驻迪拜领馆李旭航总领事</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月28日至29日，组织近300名核心人员，进行了一次锻炼队伍的野外拓展训练。</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月1日-3日，董事长兼CEO孔炯应邀出席2019年世界经济论坛新领军者年会(夏季达沃斯)</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月3日，国内首档竞技类媒体购物栏目《鲨鱼对决秀》召开新闻发布会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月6日，《鲨鱼对决秀》成功首播</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月8日，《魏征收藏的故事》成功首播。至此，聚鲨环球精选2019年四档创新栏目全部上线</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月17日，董事长兼CEO孔炯应邀在第四届中国－阿拉伯国家广播电视合作论坛上，发表题为《融合共生、共同发展》的演讲</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月20日， MODERN FUN 第七季—— 2019秋冬品牌发布直播炫酷启幕</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月24日，聚鲨环球精选&中国科学院“一带一路”产业联盟签约仪式</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月27日，《鲨鱼对决秀》第一季终极大PK隆重直播。明星主持人曹颖加盟的国内首档竞技类媒体购物栏目第一季在高潮中圆满落幕</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月29日至31日，第三届沙特未来投资倡议峰会（Future Investment Initiatives）在沙特首都举办。聚鲨环球精选副总裁、CITRUSS CEO庞微代表聚鲨环球精选集团应邀出席会议</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月5日，第二届中国国际进口博览会在上海开幕。聚鲨环球精选副总裁宋子扬等代表公司应邀参加博览会配套活动“新时期扩大进口与贸易便利化高峰论坛”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月9日至11月11日，“嗨购双十一”成交额完成1.5亿元</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月5日，2019“TV地标”中国电视媒体暨“时代之声”全国广播业综合实力大型调研成果发布会在北京广电国际酒店隆重召开。聚鲨环球精选创新购物节目《鲨鱼对决秀》荣获年度创新影响力购物节目奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月18日，内蒙古广播电视台与聚鲨环球精选签署战略合作协议，结成战略合作伙伴关系</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>12月27日，《曹颖的鲨鱼对决秀》第二季成功上线</p></div>
        <div className={style.title}>2018年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月15日，伊朗驻中国大使馆商务参赞赛义德·阿哈扎戴先生来访</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月5日，阿联酋Al Taawon Al Tam投资公司管理合伙人、citrussTV股东Rashed Al Rashed先生一行到访</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月，参与德国法兰克福春季国际消费品展览会，与菲仕乐、双立人、康宁、WOLL、米技、SwissDiamond、FLONAL、尚尼八大国际厨具品牌达成深度合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月8日，荣获2017年度中国电视购物行业年会优秀节目流行美妆类金奖、厨房食品类银奖、专案商品类银奖、居家生活类铜奖、优秀主持人奖、模式创新奖、社交媒体力传播奖</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月16日，参加2018中国家电及消费电子博览会（APPLIANCE&ELECTRONICS WORLD EXPO，简称AWE），与松下、三禾、Westinghouse、科沃斯、虎牌等多个国际知名品牌正式达成战略合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月27日，荣获2018“全国质量信用先进企业”与“全国产品和服务质量诚信示范企业”称号</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月11日，荣获“中国通讯营销行业十佳明星”奖</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月12日，旗下citrussTV与中东北非地区最具影响力的综合性媒体集团MBC达成战略合作伙伴关系</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月17日，荣获中国传媒趋势论坛“最具国际视野购物平台”荣誉</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月18日，环球购物频道正式完成中英文名称、呼号及标识的调整，全新升级为“聚鲨环球精选”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月8日，举办“精选模式引领零售趋势·聚鲨环球精选品牌发布暨全球合作伙伴大会”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18日，荣获第三届中国电视购物联盟足球赛季军</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18-20日，“周年庆88折年度钜惠” 5周年庆典活动举办</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月25日，圆满召开5周年业务研讨会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月28日，全新特色栏目《新丝绸之路》上线并推出首款商品——伊朗藏红花</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月17日，电商平台两周年庆典</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月18日，618年中狂欢节</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月20日，聚鲨环球精选旗下citrussTV与聚鲨贸易出口商品选品会在京召开</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月18日，聚鲨环球精选“全球购物节”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月18日，泰国国家珠宝首饰研究发展院（GIT）代表团莅临参观聚鲨环球精选</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月25日，刘晓庆携手goodhealth开启聚鲨环球精选健康之旅</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月25日，旗下citrussTV与中东媒体集团MBC正式签订战略合作协议</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月8日，助力首届电视购物节，打造行业文化品牌</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月18日，勇夺“2018北京市科创企业足球联赛”冠军</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月5日，在韩国获颁“亚洲电视购物杰出企业奖”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月11日，自主开发的聚鲨环球云系统正式上线</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月21日，台湾著名主持人吴宗宪携手COPPER SHEF品牌锅具亮相</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月19日，承办“第七届中国家庭购物物流联盟峰会”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月20日，携手中东媒体集团MBC在京召开内容引进座谈会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月7日，作为唯一电视购物赞助商亮相“2018中国网球公开赛”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月19日-20日，圆满举行“从心开始，勇往直前”——鲨鱼人才成长计划发布会及徒步拓展活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月24日，作为唯一亚洲企业代表受邀参加2018德国媒体峰会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月5-10日，携多款中东北非地区精选商品参展首届中国国际进口博览会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月7日，citrussTV在首届中国国际进口博览会上现场签约虎牌、松下、米技三大国际品牌</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月10-11日，“嗨购双11”两天成交破亿，创造自2013年以来的历史新高</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月20日，哇哈哈集团董事长宗庆后到访并达成战略合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月23日，与富丽华德正式签署战略签约</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月29日，通过英国BSI再认证审核，获得ISO9001质量体系认证新版证书</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月12日，打造“12.12狂欢购”双十二特别活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月19日，聚鲨学院高管培训课开课，董事长首度开讲“公牛鲨的修炼”</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>12月31日，聚鲨环球精选-全球好物年终盛典</p></div>
        <div className={style.title}>2017年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月17日，承办中国电视购物联盟“行有速 质无界”2016年度中国电视购物行业年会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月23日，荣获中国电子信息行业大会“飞马奖”奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月1日，与泰国最大电视购物运营商TVD达成战略合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月15日，举办3•15“让消费发声”特别活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月15日，荣获2017“全国产品和服务质量诚信示范企业”称号</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月24日，与北京大学政府管理学院在京举办实习实践基地签约仪式</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月11日，荣获中国通讯营销行业“十佳明星企业”奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月12日，荣获中国传媒学院奖“最具影响力全媒体零售平台” 奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月12日，荣获北京市“诚信服务承诺单位”称号</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月15日，举办“匠心2017 走进方太”大型户外直播节目</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月16日，发布4周年主视觉“FOR YOU”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月20日，承办中国电视购物联盟第二届环球购物“鲨鱼杯”足球赛</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月22日，启动MODERN FUN第三季时尚发布会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月12日，推出母亲节特别节目：花样妈妈，陪你一直美下去</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月12日，董事长孔炯应邀出席第三届定位中国峰会，并发表主题演讲</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18-20日，盛大举办4周年豪华游轮主题庆生Party</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月25日，于北京奥林匹克森林公园举办马拉松比赛</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月29日，电商平台CEO余晔受邀出席中国电子商务大会，并分享创新干货</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月6日，获酷开“最受消费者信赖奖”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月30日，荣获“全国质量信用先进企业”称号</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月6日，国际知名艺人刘晓庆亲临电视购物节目现场直播</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月11日，获得“2017中国电子商务百强”奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月23日，参展第二十六届北京国际广播电影电视展览会（BIRTV2017）</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月6日，成功举办2017“会员面对面”活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月11日，参展第十二届中国北京国际文化创意产业博览会(文博会)</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月15日，与中国网球公开赛达成合作，成为赛事指定供应商</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月20日，正式宣布收购中东最大电视购物公司citrussTV，并在阿联酋迪拜成功举办“投资控股citrussTV签约仪式暨新闻发布会”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月23日，推出大型时尚发布秀节目Modern Fun第四季</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月13日，荣获中国质量检验协会颁发的“全国质量诚信标杆典型企业”称号</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月11日，推出“嗨购双十一，聚鲨精选盛典”双十一特别活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月2日，启动“轻奢未来”第三季</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月5日，昆山仓荣获中国仓储与配送协会颁发的“中国星级仓库”（五星级）奖牌及证书</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月6日，董事长孔炯荣获“TV地标年度人物”称号</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月6日，荣获“TV地标年度优秀购物频道”荣誉</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>12年31日，打造跨年特别活动：2017年终盛典，2018启程出发</p></div>
        <div className={style.title}>2016年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月8日，荣获中国传媒学院奖“最具市场价值全媒体零售平台”奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月15日，中国质量检验协会“产品和服务质量诚信承诺”主题活动中做出诚信承诺</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月19日，打造“MODERN FUN”春季新品发布会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月12日，荣获“中国通讯营销行业十佳明星企业奖” 奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月20日，携百万礼金共襄“互联网 共享未来”主题晚会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月20日，与旅游卫视正式达成战略合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月1日，业内首推全国30天免费退换货服务</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月6日，与BTV生活频道达成战略合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月18日-20日，打造“Shopping Mall”520秀场</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月1日，与韩国公营电视购物签订战略合作协议</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月7日，在北京保利剧院举办视频电商平台发布会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>6月16日，启动“赔偿先付”制度</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月24日，参展第二十五届北京国际广播电影电视展览会（BIRTV2016）</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月24日，启动MODERN FUN第二季时尚发布会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月15日，启动“轻奢未来Ⅱ”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月21日，携手内蒙古自治区青少年发展基金会，在内蒙古包头市包钢七小举办“HIT FM环球音乐教室”公益活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月25日，承办中国电视购物联盟首届“鲨鱼杯”在京电视购物频道足球邀请赛</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月29日，通过ISO/IEC 27001:2003信息安全管理体系认证</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月1日，正式推出“光阴的故事”系列会员故事</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>12月28日，荣获“诚信兴商示范单位”称号</p></div>
        <div className={style.title}>2015年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月19日，联合华风集团开播全新栏目《天气预报》，开启全面关注民生新起点</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>1月23日，荣获“2014中国传媒学院奖·传播案例”奖项</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月1日，成为泰迪珍藏111周年泰迪熊售卖电视购物（北京站）独家合作单位</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>2月10日，登陆“精彩北京人”社区春晚</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月15日，推出首届“3.15品牌盛典”活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月18日，正式在天津地区覆盖落地</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月9日，与北京大学新闻与传播学院举行院企合作挂牌仪式</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月28日，携手中青旅在京宣布达成战略合作伙伴关系</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月20日，两周年庆典，单日销售额破1000万</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月20日，推出全新品牌广告“飘浮的橱窗”，大获好评</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月20日，新版APP正式上线</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月28日，在韩国首尔召开“环球之旅招商大会”，吸引百余家韩国中小企业赴会洽谈</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月2日，正式在上海地区覆盖落地</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月10日，跨境电商业务正式上线</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月15日，成为北京卫视《歌手是谁》综艺节目奖品赞助合作伙伴</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月18日，在2015中国-东盟博览会面向东南亚厂商举办品牌推介会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>10月14-15日，分别在天津培智学校、上海唐四小学举办“Hit FM环球音乐教室”公益活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月7日，携手韩国现代购物同步直播销售韩国明星设计师作品</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月11日，双十一会员日销售额冲破1500万，再创新高</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月2日，携手泰泓珠宝举办《功夫熊猫》系列衍生品战略发布会，成为电影衍生品全国首发平台</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>12月12日，打造“轻奢未来”时尚秀场</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>12月29日，通过英国BSI认证，获得ISO9001质量体系认证证书</p></div>
        <div className={style.title}>2014年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>3月22日，携手米娜共同开启Mina Collection 2014时装发布会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月4日，举办#会员面对面#会员尊享活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>4月20日，推出热销品牌颁奖典礼特别专场</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月17日，正式在大连地区覆盖落地</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月20日，改版一周年，破纪录推出7地连线庆生直播</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月29日，在山东高淤小学举办“Hit FM环球音乐教室”公益活动</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月29日，自制微电影《旋转吧！早苗》获中国国际微电影大典“组委会特别大奖”</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月7日，北京地区位序调整至19频道</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月12日，携手百盛集团，在百盛北京太阳宫店举行“环球百盛团结力量大”高端品牌户外特卖会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月15日，泰泓珠宝进驻《第一品牌》栏目</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月15日，与沱沱工社共同签署战略合作协议</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月18日，与泰泓珠宝共同签署战略合作协议</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月30日，携手昱海传媒开启战略合作</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月16日，荣获“变形金刚30周年展战略合作伙伴”称号，并举行战略合作仪式</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>8月29日，召开首届供应商代表大会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月28日，首开先例联合陕西、四川、辽宁、河南各省购物频道，打造《5台联播名品惠》</p></div>
        <div><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>9月28日，与电影《黄金时代》结成战略合作伙伴关系，开启电影与电视购物跨界联盟的新模式</p></div>
        <div className={style.title}>2013年</div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>5月20日，以“新环球 我爱你”全新形象改版开播</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>7月10日，应邀出席2013亚洲通讯营销产业发展大会</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>9月28日，办公区乔迁至惠通时代广场</p></div>
        <div><span className={style.spanline}></span><span className={style.spanCurcle}></span><p>11月10日，隆重首播《中国制造》</p></div>
        <div style={{marginBottom:'40px'}}><span className={style.spanline,style.lastSpanLine}></span><span className={style.spanCurcle}></span><p>11月15日，在北京绿源小学举办“Hit FM环球音乐教室”公益活动</p></div>
      </div>
    );
  }
}

export default withRouter(staticBigStory);
