import React from 'react';
import { withRouter } from 'react-router-dom';
import '@/assets/css/swiper.css';
import style from './engliseVersuion.module.scss';

class staticEngliseVersuion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {}

  render() {
    return (
      <div className={style.container}>
        <div className={style.title}>Introduction</div>
        <p>SHARK SHOPPING, an international all-media retail platform.
Our selectionmodel is leading among new retail trends. Our professional buyers adhereto a strict selection system by going on a deep dive into the countries andlocations of origin and selecting only the best local products. We aim toprovide top of the line domestic and foreign brands, especially imported andexclusive products. We base our choices upon careful consideration of ourcustomers' lifestyles, and help our customers avoid having to wade through asea of sub-par products, making their decisions simpler and providing betterquality choices.No morewasting time, SHARK SHOPPING offers you the best.
As a leader in video shopping, we are committed toproviding consumers with a "third shopping option" besidestraditional online and physical stores—we bring to customershigh-quality products from all over the worldthroughcable TV, smart screens, e-commerceapp, big data-based calls, WeChat groups, and third-party media e-commerceplatforms. Our vision is to serve hundreds of millions of families and create an ideal life for 300 millionfamilies around the world.
Combing media, retail and social advantages,we leverage on digital technology and media technology to drive the excellentinnovation and optimization of retail channel to empower merchants, brands and otherenterprises and make them achieve growth by virtue of video shopping.We continue toparticipate in the retail globalization process by investing in CITRUSS, thelargest media-based retail enterprise in the Middle East & North Africa,developing the Saudi market, building our own call center in Beirut, Lebanon,setting up a cross-border e-commerce service platform in Hong Kong, etc., andwe also drive the consumption upgrade between the Chinese retail demand sideand the global brand supply side.
SHARK SHOPPING is headquartered in Beijing, China,and makes a presence in Hong Kong of China, Dubai of UAE, Riyadh of SaudiArabia, Beirut of Lebanon and other places. It has team members from 23countries and regions and a total number of nearly 1800 employees. SHARKSHOPPING is a national high-tech enterprise of China, a council member of ChinaHome Shopping Alliance, and a vice president member of China ElectronicsChamber of Commerce. The SHARK SHOPPING Cloud System developed by SHARKSHOPPING with independent intellectual property is leading in China and alsothe first one in China's TV shopping industry. 
{/* SHARK SHOPPING has passed thesecond certification of the Quality Management Systems (ISO9001:2015) and theISO27001 Information Security Management System certification of theinternational certification authority BSI. */}
</p>
        <div className={style.title}>Select Mode Leads Retail Trend</div>
        <p><span className={style.titleSpan}>•</span>Professional buyers go on a deep dive intothe countries and locations of origin and select only the best local products.</p>
        <p><span className={style.titleSpan}>•</span>Each imported product of internationalbrands is worth buying.</p>
        <p><span className={style.titleSpan}>•</span>Strictly control every step in thehandpicking of high-quality products.</p>
        <div className={style.title}>Creatingan ideal life for 300million families around the world as a media-based retail leader</div>
        <p><span className={style.titleSpan}>•</span>SHARK SHOPPING covers 200 million familiesand 800 million viewers in China, with the high-definition andstandard-definition channels reaching    
        170 million families, the IPTV channelreaching 110 million families, and OTT smart terminals exceeding 280 million,for hundreds of millions of users    
        to watch live streaming or VOD.</p>
        <p><span className={style.titleSpan}>•</span>CITRUSS, a subsidiary of SHARK SHOPPING,covers more than 100 million families and 450 million people in the Middle East& North Africa.</p>
        <p><span className={style.titleSpan}>•</span>Broadcast 100+ hours of live content everyday through TV, mobile APP, and third-party online live streaming platforms.</p>

        <div className={style.title}>Gatheringa large number of enthusiastic customers</div>
        <p><span className={style.titleSpan}>•</span>Nearly 10 million loyal members</p>
        <p><span className={style.titleSpan}>•</span>Cumulatively more than 100,000 peopleinteract with us every day for ordering and service support.</p>
        <div className={style.title}>Empoweringmerchants, brands and other enterprises</div>
        <p><span className={style.titleSpan}>•</span>Help SMEs grow as a brand incubator andimplementation platform in the mass entrepreneurship and innovation initiative.</p>
        <p><span className={style.titleSpan}>•</span>Promote good money to drive out the bad andthe healthy development of "Made in China".</p>
        <div className={style.title}>InternationalizationStrategy</div>
        <p>SHARK SHOPPING officially announced the acquisitionof CITRUSS on September 20, 2017 to go global along the "Belt andRoad", which is the first case in China's media-based retail industry.
        CITRUSS shopping channel is broadcasted via Nilesatand Arabsat and also through Etisalat E-Life and Du cable TV networks and OSNpay-TV network in the UAE and STC network platform in Saudi Arabia,cumulatively reaching over 100 million families in the Middle East & NorthAfrica and radiating a total of 450 million people. CITRUSS has become an openinternational company after more than a decade of development, with employeesfrom 23 countries, including China, France, Belgium, Germany, India, Iran,Iraq, Kuwait, Saudi Arabia, Bahrain, Qatar, UAE, and Oman.
        The company ATT has been established in Riyadh,Saudi Arabia, to devote itself to the development of the Saudi market. CITRUSSprovides program coverage and product sources, and ATT provides import anddistribution channels and assists CITRUSS with back-end operations in SaudiArabia, to jointly provide ongoing support and services to consumers.
        The company KEYONE is a call center built byCITRUSS in Beirut, Lebanon, which started to operate independently on July 15,2018. The call center currently has a total area of 400 square meters, nearly200 employees, and a complete IT system to support business functions such ascall answering, outbound calls, WhatsApp services, and after-sales services,and its working languages are Arabic, English, French, etc.
        Furthermore, CITRUSS has modern storage centers inSaudi Arabia, UAE, Oman, Kuwait, and Bahrain.

        Since 2018, CITRUSS has entered into a historicalstage of rapid development, and its sales scale and profitability have grownsignificantly, thanks to the signing of a strategic cooperation agreement withMBC Group, securing of a prime order among channels and other channelbroadcasting resources, etc.
        MBC Group is the most influential media group andthe market leader in entertainment, news and movie channel content in theMiddle East & North Africa market, reaching more than 86% of satellite TVfamilies in the Middle East & North Africa, with 134 million Arab familieswatching MBC Group's TV channels.
        SHARK SHOPPING teams up with CITRUSS to promote theexchange of goods and cultures between China and the Middle East & NorthAfrica through joint efforts with MBC and other strategic partners.
        In the future, we will continue to advance ourinternationalization strategy, build SHARK SHOPPING into an importantcommercial retail channel and cultural dissemination platform along the"Belt and Road", promote the two-way flow of goods between the Chinesemarket and overseas markets, and contribute to cultural exchanges in countriesalong the "Belt and Road" and enhancing the comprehensive influenceof Chinese culture.
        </p>
        <div className={style.title}>CorporateCulture</div>
        <p><span className={style.titleSpan}>•</span>Mission</p>
        <p>Our mission is to create an ideal life for 300 million families around theworld.No morewasting time, SHARK SHOPPING offers you the best.Our professional buyers go on a deep dive into the countries and locations of originand select only the best local products, making customers' decisions simplerand providing better quality choices. We believe that the ways of shopping andliving habits of every customer and every family will change because of ourefforts, to not only save more time but also have a better quality of life.</p>
        <p><span className={style.titleSpan}>•</span>Vision</p>
        <p>Our vision is to become a respected enterprise anda world-known media-based retail group. We advocate all employees, partners andcompanies to join sustainable public welfare undertakings. We are also alwaysopen to explore multifaceted cooperation with outstanding partners in variousfields. With the application of digital and media technologies in the retailindustry, we are building an integrated and symbiotic business ecosystem thatwill not only create business opportunities for excellent global brands andSMEs but also bring a more people-oriented shopping experience to hundreds ofmillions of customers and families.</p>
        <p><span className={style.titleSpan}>•</span>Sharkspirit</p>
        <p>Shark is the spiritual totem of SHARK SHOPPING.</p>
        <p>Sharks are the toughest creature in the oceandespite their cartilaginous skeletons, and they have highly strong coordinationand fast environmental adaptability. Enterprises should learn from sharksbecause they can continue to grow and develop only by constantly adapting tothe environment. Sharks change 30,000 teeth in their lifetime, where when thefront-row teeth are off, the back row will fill, which takes place during theirlifetime so that teeth become their most formidable weapon. Enterprises shouldlearn from sharks because they must stick to learning to constantly fill in andimprove their leading superiority so as to ensure strong core competence.Sharks are the only fish without a swim bladder; therefore, they will sink tothe bottom if they do not keep swimming, just like an excellent enterprise mustkeep forging ahead to maintain its strength, no matter how strong it is. In thejourney of realizing a beautiful vision, we hope to travel the world with partnerswho have the shark spirit.</p>

        <p><span className={style.titleSpan}>•</span>Behaviorcharacteristics</p>
        <p>Dare to be the first</p>
        <p>Pursue excellence</p>
        <p>Praise acts of heroism</p>
        <p>Uphold an open mind</p>
        <p><span className={style.titleSpan}>•</span>Outlookon Talent</p>
        <p style={{marginBottom:'40px'}}>Dream, loyalty, learning, action</p>
      </div>
    );
  }
}

export default withRouter(staticEngliseVersuion);
