const { protocol } = window.location; // 获取当前用户访问的URL使用的协议
const { host } = window.location; // 获取当前用户访问URL主机信息
let yunBase; // 环球云根地址

if (host.indexOf('sharkshopping.com') > -1 || host.indexOf('ghs.net') > -1) {
  if(host.indexOf('win-') > -1) { // 微软云环境
    yunBase = 'win-api.ghs.net'; // 云商城正式环境授权模块根目录
  } else if (host.indexOf('www2.sharkshopping.com') > -1) { // 备机
    yunBase = 'api2.sharkshopping.com'; // 云商城正式环境授权模块根目录
  } else {
    yunBase = 'api.sharkshopping.com'; // 云商城正式环境授权模块根目录
  }
} else {
  yunBase = 'mall.api.test.globalgo.com.cn'; // 环球云测式环境授权模块根目录
}

// 接口的根地址
const baseURL = `${protocol}//${yunBase}/ec/api?method=`;

export default {
  invoiceDownload: `${baseURL}pc.invoice.query.download`,
  indexTopImg: `${baseURL}pc.indexTopImg`,
  articleList: `${baseURL}article.latestInformation`,
  searchLogistics: `${baseURL}b2c.logistics.info2.search_logistics_pc`, // 物流查询
  qrcode: `${baseURL}pc.goods.qrcode`, // 生成图片二维码
  articleListByCat: `${baseURL}pc.article.articleListByCat`, // pc文章管理-栏目下的文章列表
};
