import React from 'react';
import style from './footer.module.scss';
import setImg from '@/utils/imgs';

class Minfooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lazyLoadImg: setImg('lazyload.png'), // 默认图
      zhiHover:false,
      lianHover:false,
      zhaoHover:false
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
  }
  setZhiHover(){
    this.setState({zhiHover: !this.state.zhiHover})
  }
  setLianHover(){
    this.setState({lianHover: !this.state.lianHover})
  }
  setZhaoHover(){
    this.setState({zhaoHover: !this.state.zhaoHover})
  }
  setZhiClick(){
    window.open('https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin/image/100536/1026091512-1_8572be17.jpg');
  }
  setLianClick(){
    window.open('https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin/image/100886/1026091631-10_fa73a7e0.jpg');
  }
  setZhaoClick(){
    window.open('https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin/image/100304/1026111837--20201026111801_1c3ec051.jpg');
  }
  render() {
    const { lazyLoadImg} = this.state;
    return (
      <div className={style.container}>
        <div className='min-foot'>
          <span>Copyright ©2011-2017 GHS.NET &nbsp;&nbsp;服务热线：<br/><br/>4000-521-521</span>
          <span 
          onClick={() => this.setZhiClick()}
          >&nbsp;&nbsp;&nbsp;&nbsp;营业执照&nbsp;&nbsp;友情链接</span></div>
        <div>
          <span
          onClick={() => this.setLianClick()}>食品经营许可证备案号：JY11107051364804<br/></span><br/>
          <span
          onClick={() => this.setZhaoClick()}>经营证照聚鲨环球精选 &nbsp;&nbsp;版权所有&nbsp;&nbsp;&nbsp;&nbsp;京ICP备16048467号-2</span>
        </div>
        <div className={style.gonganju}>
          <img className={style.gonganjuImg} src={setImg('gonganju.png') || lazyLoadImg} alt="微信扫码二维码" />
          <div>京公网安备 11010502043792号</div>
        </div>
      </div>
    );
  }
}

export default Minfooter;
