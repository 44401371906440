function findImg(imageName) {
  const requireContext = require.context('../assets/imgs/PCstatic', true, /^\.\/.*\.(png|jpe?g|gif|svg)$/);
  const projectImgs = requireContext.keys().map(requireContext);
  const imgNameArr = requireContext.keys();
  const pattern = /\w+\.\w{3}/g;
  let imgurl = '';
  for (let i = 0; i < imgNameArr.length; i += 1) {
    const imgName = imgNameArr[i].match(pattern)[0];
    if (imgName.indexOf(imageName) === 0) {
      imgurl = projectImgs[i];
    }
  }
  return imgurl;
}

export default findImg;
