import axios from 'axios';
import Qs from 'qs';
import md5 from 'js-md5';

// axios 配置
axios.defaults.timeout = 50000;

// http request 封装请求头拦截器
axios.interceptors.request.use((config) => {
    // get请求
    const method = config.method.toLowerCase();
    // const keyScreen = 'f636f9f48eeeead453289e5254e3706778f639ab247887c99cc79788615def21'; // 测试
    const keyScreen = 'e662633040d6a433d48580a38fcedc49c9ba5d015dccf701096abade0c623163'; // 正式

    if (method === 'get') {
      let array = config.params;
      const obj = {};
      let str = '';
      // 如果没有params要处理一下，给config增加params对象(没有参数的这种情况，不加密也可以，后台没有验证---2021.7.27)
      if (!array) {
        config.params = {};
        array = config.params;
      }
      // 1、时间戳参数加进去
      array.timestamp = new Date().getTime();
      // 2、按照参数大小进行排序,按照keyvalue的拼接方式拼接成字符串
      Object.keys(array).sort().map((item) => {
        obj[item] = array[item];
        if (array[item] === null || array[item] === undefined) {
          // 这两种情况不gn拼接进来
        } else {
          // 如果是对象类型
          if (obj[item] instanceof Object) {
            obj[item] = JSON.stringify(obj[item]);
          }
          str = str + item + obj[item];
        }
        return str;
      });
      // 3、进行md5&key&大写的方式加密
      const signStr = md5(md5(str).toUpperCase() + keyScreen).toUpperCase();
      // 4、赋值
      Object.assign(config.params, {
        sign: signStr,
      });
    } else {
      // post 请求
      const getParams = config.data;
      const array = Qs.parse(getParams);
      const obj = {};
      let str = '';
      // 1、判断是否有时间戳参数，如果没有就加进去
      if (!Object.prototype.hasOwnProperty.call(array, 'timestamp')) {
        array.timestamp = new Date().getTime();
      }
      // 2、按照参数大小进行排序,按照keyvalue的拼接方式拼接成字符串
      Object.keys(array).sort().map((item) => {
        obj[item] = array[item];
        // 如果是对象类型
        if (obj[item] instanceof Object) {
          obj[item] = JSON.stringify(obj[item]);
        }
        str = str + item + obj[item];
        return str;
      });
      // 3、进行md5&key&大写的方式加密
      const signStr = md5(md5(str).toUpperCase() + keyScreen).toUpperCase();
      // 4、赋值
      config.data = `${getParams}&sign=${signStr}`;
    }
  return Promise.resolve(config);
}, () => {
});

// TODO response 拦截
axios.interceptors.response.use((response) => {
  if (response.status === 200) {
    if (response.data && response.data.rsp && response.data.rsp === 'succ') { // 接口服务级错误识别
      if (response.data.status && response.data.status === 'succ') { // 接口级错误识别
        const responseData = response.data.data;
        return Promise.resolve(responseData); // 返回
      }
      if (response.data.status && response.data.status === 'paras-error') { // 参数错误
        console.log('数据服务异常[参数异常]<br />请联系客户人员');
      }
    } else {
      console.log('数据服务异常<br />请联系客户人员');
    }
  } else if (response.status === 500) {
    console.log('数据服务异常[500]<br />请联系客户人员');
  } else if (response.status === 404) {
    console.log('数据服务异常[404]<br />请联系客户人员');
  } else if (response.status === 204 || response.status === 422) {
    // 数据埋点时，200和204表示成功，422（无法处理的请求实体）表示没进入数据埋点接口
    return Promise.resolve(response);// 返回
  } else {
    console.log('数据服务异常[error]<br />请联系客户人员');
  }
  const returnData = {
    returndata: {
      status: 'error',
    },
  };
  return Promise.resolve(returnData);
}, () => {
  const returnData = {
    returndata: {
      status: 'error',
    },
  };
  return Promise.resolve(returnData);
});

export default axios;
