import React from "react"
import setImg from '@/utils/imgs';
import '../../index.scss';

class Table extends React.Component{
    constructor(props) {
        super(props);
        this.state = { 
          list: [
            {
              id: 1,
              title: '品牌赋能',
              title2: '品效合一',
              bgimg: setImg('Group155.png'),
              activebg: setImg('Group1ico.png'),
              conter1: '聚鲨环球精选致力于帮助品牌和供应商实现品牌与销售的双增长。',
              conter2: '聚鲨环球精选遵循商品开发五力模型，精选全球好物。以品牌力、功能性、流行性、便利性、性价比等五大核心要素为基准，构建了商品开发的五力模型，对商品进行多维度评分，为消费者精选全球好物。',
              conter3: '聚鲨环球精选进口品牌销售>60%。采用买手机制，深入原产国、原产地寻找当地“好货”，旨在提供源自最佳产地的国内外一线品牌，尤其是进口和独家销售商品，让顾客远离无序海量，让选择更简单且更有品质。',
            },
            {
              id: 2,
              title: '内容制作',
              title2: '节目策划',
              bgimg: setImg('Group154.png'),
              activebg: setImg('Group2ico.png'),
              conter1: '聚鲨环球精选拥有业内顶尖的制作团队和主持人团队，通过多年内部培养及市场检验，能够有效传递品牌故事和商品价值，高效地引导消费者进行购买决策。针对战略合作品牌，聚鲨环球精选量身打造品牌专场栏目，最大化品牌销售产出。 ',
              conter2: '围绕关键节庆及营销节点，开展如“5.20周年庆”、“6.18”、“MODERN FUN”、“双十一”等大型促销活动，为品牌提供从商品策划、制作包装、节目呈现到销售变现的一站式服务。' ,
            },
            {
              id: 3,
              title: '数字化',
              title2: '智能化',
              bgimg: setImg('Group156.png'),
              activebg: setImg('Group2ico3.png'),
              conter1: '聚鲨环球精选是国家高新技术企业。持续推进自主产权研发，取得软件著作权累计超过50项，已初步搭建起数字化、智能化的用户运营平台。',
              conter2: '聚鲨环球精选自主研发环球云系统。实现包括客户关系管理系统、大数据外呼系统、物流管理系统、仓储管理系统、合同管理系统和结算管理系统等在内的全面自主研发。 ',
            },
            {
              id: 4,
              title: '多渠道',
              title2: '多平台',
              bgimg: setImg('Group160.png'),
              activebg: setImg('Group2ico4.png'),
              conter1: '聚鲨环球精选持续拓展业务渠道，已发展成为多渠道、多平台的媒体零售企业。涵盖卫星电视、有线电视、IPTV、OTT、电商、微信、社群、大数据营销等多个业务渠道。 ',
              conter2: '聚鲨环球精选下设直播事业部，基于腾讯视频、抖音、快手等第三方平台开展手机直播、IP运营、短视频策划，并对旗下主播进行孵化，充分发挥直播带货和供应链资源优势，快速布局全生态的销售矩阵和零售格局。 ',
            },
            {
              id: 5,
              title: '鲨鱼出海',
              bgimg: setImg('Group157.png'),
              activebg: setImg('Group2ico5.png'),
              conter1:'2017年9月20日，聚鲨环球精选在阿联酋迪拜召开投资控股CITRUSS签约仪式暨新闻发布会，宣布完成对CITRUSS的收购交割，正式进军中东北非市场。此次收购是中国媒体零售行业第一个海外收购案例，聚鲨环球精选成为行业内第一家走出去的企业。',
              conter2: 'CITRUSS是中东北非地区最大的媒体零售企业。总部位于阿联酋迪拜，在黎巴嫩贝鲁特自建有呼叫中心与外呼团队，沙特利雅得设有贸易公司，在阿联酋、沙特、巴林、阿曼分布有现代化仓储中心。',
              conter3: 'CITRUSS通过卫星电视频道、Windows时段、移动App、第三方社交媒体平台，以及大数据营销渠道，为中东北非地区1亿家庭、4.5亿人口提供商品服务。CITRUSS现有员工近300人，分别来自23个国家与地区。 ',
            },
          ],
          currentIndex: 0,
        };
      }

    check_title_index( index ){
        return index === this.state.currentIndex ? "tab_title active" : "tab_title"
    }

    check_item_index( index ){
        return index === this.state.currentIndex ? "tab_item show" : "tab_item"
    }

    render(){
        const { list } = this.state;
        return(
          <div className='what'>
              <div className="tab_title_wrap">
                  { 
                      list.map( ( item,index ) => {
                          return(
                              <div key={item.id} onClick={ () => { this.setState({ currentIndex : index }) } } className={ this.check_title_index( index )  } >
                                 
                                 
                                  {/* <div className='icons' style={{background: `url(${this.state.currentIndex === index ? item.activebg : item.bgimg}) center no-repeat`, backgroundSize: '100%'}}></div> */}
                                  <div className='icons'>
                                  <img style={{'width':'80%','height':'80%'}} src={this.state.currentIndex === index ? item.activebg : item.bgimg}></img>
                                  </div>

                                    <p>{ item.title }</p>
                                    <p>{ item.title2 }</p>
                              </div>
                          )
                      }) 
                  }
              </div>

              <div className="tab_item_wraps">
                  {
                      list.map(( item,index )=>{
                          return(
                              <ul key={item.id} className={ this.check_item_index( index ) }>
                                  <li>{item.conter1}</li>
                                  <li>{item.conter2}</li>
                                  {
                                    item.conter3 ? <li>{item.conter3}</li> : ''
                                  }
                                  
                              </ul>
                          )
                      })
                  }
              </div>

          </div>
        )
    }
}

export default Table;