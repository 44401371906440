import React from 'react';
import { withRouter } from 'react-router-dom';
import setImg from '@/utils/imgs';
import './InvoiceQuery.scss';
import common from '@/utils/common';
import { invoiceDownload, searchLogistics } from '@/config';

class InvoiceQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceCode: '', // 发票代码
      invoiceTitle: '', // 发票抬头
      invoiceNumber: '', // 发票号码
      pdfUrl: '', // pdf的下载地址
      inputText: '',
      isShowFpInfo: false,
      inputFlag:false,
      isShowMassage:false,
      massage:'查询码不正确哦'
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
  }
  // 监听input 输入框
  inputChange = async (event) => {
    this.setState({
      inputText: event.target.value,
    });
    if (event.target.value) {
      this.setState({
        inputFlag:true,
      })
    }else{
      this.setState({
        inputFlag:false,
        isShowFpInfo: false,
        isShowMassage:false
      })
    }
  };
  // 查询功能
  sendForm = async () => {
    this.setState({
      invoiceCode: '', // 发票代码
      invoiceTitle: '', // 发票抬头
      invoiceNumber: '', // 发票号码
      pdfUrl: '',
    });
    if (this.input.value === '') {
      this.setState({
        massage: '请输入查询码',
        isShowMassage:true
      });
      let that = this
      setTimeout(function(){
        that.setState({
          isShowMassage:false
        })
      },3000)
      return;
    }else{
      this.setState({
        massage: '查询码不正确哦',
      });
    }
    this.handleClick(this.input.value.replace(/(^\s*)|(\s*$)/g, ''));
  };
  handleClick = async (data) => {
    const reuqest = () => {
      let params = {};
      let typeUrl;
      params = {
        source: common.source,
        version: common.version,
        clientId: common.clientId,
        invoiceCode: data,
      };
      typeUrl = invoiceDownload(params);
      return typeUrl;
    };
    const response = await reuqest();
    if (response.returndata) {
      const invoiceInfo = response.returndata;
      if (response.returndata.status === 0) {
        this.setState({
          invoiceCode: invoiceInfo.invoiceInfo.yfphm,
          invoiceTitle: invoiceInfo.invoiceInfo.gmfmc,
          invoiceNumber: invoiceInfo.invoiceInfo.yfpdm,
          pdfUrl: invoiceInfo.invoiceInfo.downPath,
          isShowMassage: false,
          isShowFpInfo: true
        });
      } else {
        this.setState({
          invoiceCode: '',
          invoiceTitle: '',
          invoiceNumber: '',
          pdfUrl: '',
          isShowMassage: true,
          isShowFpInfo:false,
        });
        console.log(this.state.massage);
      }
    } else {
      this.setState({
        isShowMassage:true
      });
    }
    let that = this
    setTimeout(function(){
      that.setState({
        isShowMassage:false
      })
    },3000)
  };
  // 下载发票的pdf功能
  dowloadPdf = async () => {
    const { pdfUrl } = this.state;
    if (pdfUrl === '') {
      return;
    }
    window.open(pdfUrl);
  };
  render() {
    const {inputText, invoiceCode, invoiceTitle, invoiceNumber,massage, isShowMassage,isShowFpInfo,inputFlag} = { ...this.state };
    return (
      <div className="invoicecontainer">
        <div className='invoicetitle'>电子发票查询</div>
        <div className='invoiceinputDiv'>
          <input type="text" placeholder='请输入查询码' ref={(input) => { this.input = input; }} value={inputText} onChange={this.inputChange.bind(this)}/>
          <div className={`${inputFlag ? 'searchBtn active' : 'searchBtn'}`} onClick={this.sendForm}>查询</div>
          <div className="clearfloat"></div>
          {/* {isShowMassage&&
          <div>
            <div className='isNoinfo'>
              <img src={setImg('warningImg.gif')} alt="警告图标" />
              <p className='isNoinfoMessage'>{ message }</p>
            </div>
          </div>
          } */}
          {isShowFpInfo &&
          <div className='invoiceInfo'>
            <div><span>发票代码：</span><span>{invoiceCode}</span></div>
            <div><span>发票抬头：</span><span> {invoiceTitle }</span></div>
            <div><span>发票号：</span><span>{invoiceNumber}</span></div>
            <div className='lastBtn' onClick={() => { this.dowloadPdf(); }}>
              <img src={setImg('invoiceDownload.png')} alt="点击下载发票" />
              <span>点击下载</span></div>
          </div>
          }
        </div>
        {isShowMassage&&
          <div className='errorTost'>{ massage }</div>
        }
      </div>
    );
  }
}

export default withRouter(InvoiceQuery);
