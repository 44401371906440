import React from 'react';
import { withRouter } from 'react-router-dom';
import Swiper from 'swiper';
import setImg from '@/utils/imgs';
import '@/assets/css/swiper.css';
import './brand.scss';

class staticBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swiperList: [
        {
          id: 0,
          content: [
            { id: '01', img: setImg('Group67.png') },
            { id: '02', img: setImg('Group69.png') },
            { id: '03', img: setImg('Group71.png') },
            { id: '04', img: setImg('Group72.png') },
            { id: '05', img: setImg('Group74.png') },
            { id: '06', img: setImg('Group75.png') },
            { id: '07', img: setImg('Group76.png') },
            { id: '08', img: setImg('Group77.png') },
            { id: '09', img: setImg('Group78.png') },
            { id: '10', img: setImg('Group80.png') },
            { id: '11', img: setImg('Group81.png') },
            { id: '12', img: setImg('Group82.png') },
            { id: '13', img: setImg('Group83.png') },
            { id: '14', img: setImg('Group84.png') },
            { id: '15', img: setImg('Group85.png') },
            { id: '16', img: setImg('Group86.png') },
            { id: '17', img: setImg('Group87.png') },
            { id: '18', img: setImg('Group88.png') },
            { id: '19', img: setImg('Group89.png') },
            { id: '20', img: setImg('Group90.png') },
            { id: '21', img: setImg('Group91.png') },
            { id: '22', img: setImg('Group92.png') },
            { id: '23', img: setImg('Group93.png') },
            { id: '24', img: setImg('Group94.png') },
          ],
        },
        {
          id: 1,
          content: [
            { id: '25', img: setImg('Group95.png') },
            { id: '26', img: setImg('Group96.png') },
            { id: '27', img: setImg('Group97.png') },
            { id: '28', img: setImg('Group98.png') },
            { id: '29', img: setImg('Group99.png') },
            { id: '30', img: setImg('Group100.png') },
            { id: '31', img: setImg('Group101.png') },
            { id: '32', img: setImg('Group102.png') },
            { id: '33', img: setImg('Group103.png') },
            { id: '34', img: setImg('Group104.png') },
            { id: '35', img: setImg('Group105.png') },
            { id: '36', img: setImg('Group106.png') },
            { id: '37', img: setImg('Group107.png') },
            { id: '38', img: setImg('Group108.png') },
            { id: '39', img: setImg('Group109.png') },
          ],
        },
      ],
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
    this.swiper = new Swiper('.swiper-container', {
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  render() {
    const { swiperList } = this.state;
    console.log(swiperList);
    return (
      <div className='brandcontainer'>
        <div className='name'>合作品牌</div>
        <div className='container'>
          <div className='swiper-container swiperDiv'>
            <div className="swiper-wrapper">
              {/* <ul className="swiper-slide">sdf</ul>
              <ul className="swiper-slide">sddfdfdf</ul> */}
              {
                swiperList && swiperList.map((item) => (
                  <div className="swiper-slide" key={item.id}>
                    <ul className='listDiv'>
                      {
                        item && item.content.map((i) => (
                          <li key={i.id} className='listLi'>
                            <img className='Swiperimg' src={i.img} alt="广告图" />
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ))
              }
            </div>
          </div>
          <div className='swiper-pagination pagination'/>
          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />
        </div>
      </div>
    );
  }
}

export default withRouter(staticBrand);
