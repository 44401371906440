import React from 'react';
import './index.scss';
import { articleListByCat } from '@/config';
import common from '@/utils/common';
import { formatDataStr } from '@/utils/date';
import Pagination from '@/components/common/pagination';
class NewsInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      articleList: [],
      params: {
        source: common.source,
        version: common.version,
        clientId: common.clientId,
        type: 1,
        pageIndex: 1,
        pageSize: 10,
      },
    };
  }

  componentWillMount() {
    const { params } = { ...this.state };
    this.getData(params);
  }

  getData = async (params) => {
    const response = await articleListByCat(params);
    const list = response.returndata.articleInfo;
    if (response.returndata) {
      this.setState({
        articleList: list.articleList,
        name: list.name,
        total: response.returndata.totalCount,
      });
    } else {
    }
  }

  toDetail = async (item) => { //跳转文章内容页
    const { history } = { ...this.props };
    // history.push({ pathname: `${item}` });
    window.location.href=`${item}`
  }

  NumChange = (pageIndex) => {
    const { params } = { ...this.state };
    this.setState({
      params: Object.assign(params, { pageIndex }),
    });
    this.getData(params);
  }

  render() {
    const formatDate = (time) => {
      if (time) {
        return formatDataStr(time, 'yyyy-MM-dd hh:mm');
      }
      return '';
    };
    const { articleList, name, total, params} = { ...this.state };
    return (
      <div className="newsInformation">
        <div className="bann">
        <h2>{name}</h2>
        {
          articleList && articleList.map((item) => (
            <div key={item.id}>
              <div className="bannernew">
                <div className="context" onClick={this.toDetail.bind(this,item.link)}>
                    <div className="lititle">{item.title}</div>
                    {/* <div className="litime">{formatDate(item.createTime)}</div> */}
                </div>
              </div>
            </div>
          ))
        }
        </div>
        <div className="page">
                    <Pagination
                      onChange={this.NumChange}
                      defaultPageSize={params.pageSize}
                      current={params.pageIndex}
                      total={total}
                    />
        </div>
      </div>
    );
  }
}

export default NewsInformation;
