import React from 'react';
import { withRouter } from 'react-router-dom';
import Swiper from 'swiper';
import setImg from '@/utils/imgs';
import '@/assets/css/swiper.css';
import './topspwire.scss';

class Topspwire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swiperList: [
        {
          id: 1,
          bgimg: setImg('banner_bg.png'),
          leftimg: setImg('Group46.png'),
        },
        {
          id: 2,
          bgimg: setImg('banner_bg.png'),
          leftimg: setImg('Group47.png'),
        },
        {
          id: 3,
          bgimg: setImg('banner_bg.png'),
          leftimg: setImg('Group48.png'),
        },
      ],
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
    this.swiper = new Swiper('.swiper-container', {
      // 如果需要分页器
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet(index, className) {
          const str = `<span class="point ${className}"></span>`;
          return str;
        },

      },
      direction: 'vertical',
      centeredSlides: true,
    });
  }

  render() {
    const { swiperList } = this.state;
    console.log(swiperList);
    return (
      <div className='indexcontainer'>
        <div className='swiper-container swipercontainer'>
          <div className="swiper-wrapper">
            {
              swiperList && swiperList.map((item) => (
                <div className="swiper-slide" key={item.id}>
                  {/* <img className={style.Swiperimg} src={item.bgimg} alt="广告图" /> */}
                  <div className='swiperDIV'>
                    <img className='leftImg' src={item.leftimg} alt="   " />
                  </div>
                </div>
              ))
            }
          </div>
          <div className='swiper-pagination pagination' />
          <img className='rightImg' src={setImg('erweima.png')}  alt="二维码" />
        </div>
      </div>
    );
  }
}

export default withRouter(Topspwire);
