// PC静态改版
import PCStaticIndex from '../components/PCStatic/index/index';
import PCStaticBrand from '../components/PCStatic/brand/brand'; // 合作品牌页
import PCStaticMediaDateBase from '../components/PCStatic/mediaDateBase/mediaDateBase'; // 媒体资料库
import PCStaticAboutUs from '../components/PCStatic/aboutUs/aboutUs'; // 关于我们
import Partnership from '../components/PCStatic/Partnership/index';
import Business from '../components/PCStatic/Business/index';
import NewsInformation from '../components/PCStatic/NewsInformation/index';
import Responsibility from '../components/PCStatic/Responsibility/index';
import InvoiceQuery from '../components/PCStatic/InvoiceQuery/InvoiceQuery';//
const routes = [
  {
    path: '/',
    component: PCStaticIndex,
    exact: true,
  },
  {
    path: '/home',
    component: PCStaticIndex,
    exact: true,
  },
  {
    path: '/PCStaticBrand',
    component: PCStaticBrand,
  },
  {
    path: '/PCStaticAboutUs',
    component: PCStaticAboutUs,
  },
  {
    path: '/partnership', // 合作关系-成为供应商
    component: Partnership,
  },
  {
    path: '/business', // 合作关系-商务合作
    component: Business,
  },
  {
    path: '/newsInformation', // 新闻动态
    component: NewsInformation,
  },
  {
    path: '/responsibility', // 社会责任
    component: Responsibility,
  },
  {
    path: '/PCStaticMediaDateBase',
    component: PCStaticMediaDateBase,
  },
  {
    path: '/InvoiceQuery',
    component: InvoiceQuery,
  }
];
export default routes;
