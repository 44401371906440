import axios from '../utils/axios';

export default function ajax(url, data = {}, type = 'get') {
  return new Promise((resolve) => {
    let promise;
    // 1.执行异步ajax请求
    if (type === 'get') {
      promise = axios.get(url, {
        params: data,
      });
    } else {
      promise = axios.post(url, data);
    }
    // 2.如果成功了，调用resolve（value）
    promise.then((res) => {
      resolve(res);
    // 3.如果失败了，不调用reject（value），而是提示异常信息
    }).catch((error) => {
      console.log('请求出错了', error.message);
    });
  });
}
