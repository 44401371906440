import React from 'react';
import { withRouter } from 'react-router-dom';
import Topspwire from './home/components/Topspwire/Topspwire.jsx';
import StaticHome from './home/index.jsx';
import Minfooter from '../minfooter/footer.jsx';
import './index.scss'

class staticIndex extends React.Component {
  render() {
    return (
      <div className="page">
        <Topspwire></Topspwire>
        <StaticHome></StaticHome>
        <Minfooter></Minfooter>
      </div>
    );
  }
}

export default withRouter(staticIndex);
