import APIURL from './APIURL';
import ajax from './ajax';

export const articleList = (params) => ajax(APIURL.articleList, params, 'get');
export const invoiceDownload = (params) => ajax(APIURL.invoiceDownload, params, 'get');
export const searchLogistics = (params) => ajax(APIURL.searchLogistics, params, 'get');
export const indexTopImg = (params) => ajax(APIURL.indexTopImg, params, 'get');
export const qrcode = (params) => ajax(APIURL.qrcode, params, 'get');

export const articleListByCat = (params) => ajax(APIURL.articleListByCat, { ...params }, 'get');
