/**
 * 分页插件
 * 常用参数列举，如需使用其他功能查看下方链接。
 * https://www.npmjs.com/package/rc-pagination/v/1.20.11
 * @param disabled 是否禁用分页 Bool
 * @param defaultCurrent 默认页面 Number 1
 * @param current 当前页面 Number
 * @param total 总条数 Number 0
 * @param defaultPageSize 默认每页多少条 Number 10
 * @param pageSize 每页多少条 Number 10
 * @param onChange 切换页数回调 Function(current, pageSize)
 * @param showQuickJumper 快速跳转到某页 Bool
 * @param showTotal 显示总数及范围 Function(total, [from, to])
 * @param hideOnSinglePage 隐藏在单页上 Bool
 * @param className 添加样式名 String
 */
import React from 'react';
import PropTypes from 'prop-types';
import RcPagination from 'rc-pagination';
// import 'rc-pagination/dist/rc-pagination.min.css'
import '@/assets/css/rc-pagination.css';

class Pagination extends React.Component {
  constructor(...rest) {
    super(...rest);
    this.state = {};
  }

  render() {
    return (
      <RcPagination {...this.props} />
    );
  }
}

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default Pagination;
