import 'core-js/es'
import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render( < App / > , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();