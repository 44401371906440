import React from 'react';
// import Table from '../commpone/Table';
import './index.scss';
import imagebs from '../image/banner@2x.png';
import conta from '../image/imglianxi@2x.png';
import means from '../image/imgziliao@2x.png';
import group47 from '../image/group47@2x.png';

class Partnership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
  }

  render() {
    return (
      <div className="ps">
        <div className="psbanner">
          <img className="imgps" src={imagebs} alt="" />
        </div>
        <div className="title">
          <h2>第一步：合作渠道及联系方式</h2>
          <img className="onetitle" src={conta} alt="" />
          <h3>*以上电话只受理供应商合作洽谈，商品售后问题请拨打服务电</h3>
          <h2>第二步：提交产品及公司相关资料</h2>
          <h4>供应商合作资料列表</h4>
          <img className="onetitle" src={means} alt="" />
          <h2>第三步：聚鲨环球精选商品开发部将引导您展开后续合作流程</h2>
          <img className="onetitle" src={group47} alt="" />
          <h5>了解更多招商详情，请点击
            <a href="https://wp.sharkshopping.com/static/pdf/InvestManual.pdf" className="xiaz">下载手册</a>
          </h5>
        </div>
      </div>
    );
  }
}

export default Partnership;
