import React from 'react';
import './mediaDateBase.scss';
import { withRouter } from 'react-router-dom';
import Swiper from 'swiper';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import setImg from '@/utils/imgs';
import '@/assets/css/swiper.css';
class staticMediaDateBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleList: [
        { id: 1, img: setImg('Group60.png') },
        { id: 2, img: setImg('Group110.png') },
        { id: 3, img: setImg('Group112.png') },
        { id: 4, img: setImg('Group114.png') },
        { id: 5, img: setImg('Group115.png') },
        { id: 6, img: setImg('Group118.png') },
        { id: 7, img: setImg('Group119.png') },
        { id: 8, img: setImg('Group121.png') },
        { id: 9, img: setImg('Group122.png') },
        { id: 10, img: setImg('Group128.png') },
        { id: 11, img: setImg('Group129.png') },
        { id: 12, img: setImg('Group130.png') },
      ],
      adList: [
        {
          id: 1,
          img: setImg('Rectangle79.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100419/0714094145_p10s170416a中国篇改 lt.mp4',
        },
        {
          id: 2,
          img: setImg('Rectangle81.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100217/0714094448_P15S170101A聚鲨商城logo演绎lt.mp4',
        },
        {
          id: 3,
          img: setImg('Rectangle88.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100486/0714094422_p10s170508a英国篇改lt.mp4',
        },
        {
          id: 4,
          img: setImg('Rectangle85.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100993/0714094435_p10s170515a泰国篇改lt.mp4',
        },        {
          id: 5,
          img: setImg('Rectangle84.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100835/0714094524_P15S170306C北欧篇.mp4',
        },
        {
          id: 6,
          img: setImg('Rectangle95.jpg'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100901/0714094512_P15S170306B东方篇.mp4',
        },
        {
          id: 7,
          img: setImg('Rectangle98.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100183/0714094500_P15S170306A巴洛克篇lt.mp4',
        },
      ],
      mediaList: [
        {
          id: 5,
          img: setImg('Rectangle92.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100322/0714094619_P20S210513C-聚鲨8周年-混剪袁也封面.mp4',
        },
        {
          id: 3,
          img: setImg('Rectangle91.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100374/0714094707_P30S210507G-聚鲨8周年-理想生活篇.mp4',
        },
        {
          id: 4,
          img: setImg('Rectangle96.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100881/0714094559_P20S210513B-聚鲨8周年-混剪美娜封面.mp4',
        },
        {
          id: 1,
          img: setImg('Rectangle89.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100576/0714094639_P30S210517A-8周年-家人篇lt.mp4',
        },
        {
          id: 2,
          img: setImg('Rectangle90.png'),
          url: 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100999/0714094539_P20S210513A-聚鲨8周年-混剪张帅封面.mp4',
        },
      ],
    };
  }
  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
    this.checkPlay();
    this.swiper = new Swiper('.swiper-container', {
      autoplay:  {
        delay: 1000,
      },
      speed:2500,
      waitForTransition: false,
      disableOnInteraction: false,
      slidesPerView: 4,
      spaceBetween:8,
    });
  }
  checkPlay (){
      var videos = document.getElementsByTagName("video");
      for(var i = 0; i <videos.length; i++ ){
          videos[i].addEventListener("playing",function(){
              var this1 = this;
              this.play();
              for(var j=0; j<videos.length; j++){
                  var this2 = videos[j];
                  if(this1 !== this2){
                      this2.pause();
                };
              };
          });
      };
  }
  render() {
    const { peopleList, adList, mediaList } = this.state;
    return (
      <div className='mediacontainer'>
        <div className='mediatitle'>媒体资料库</div>
        <div className='twoTitle'>主持人</div>
        <div className="swiper-container hotPeople">
          <ul className="swiper-wrapper">
            {
              peopleList && peopleList.map((item) => (
                <li className='swiper-slide hostLi' key={item.id}>
                  <img className='Swiperimg' src={item.img} alt="广告图" />
                </li>
              ))
            }
          </ul>
        </div>
        <div className='twoTitle'>广告宣传片</div>
        <div className="swiper-container adSwiper">
          <ul className="swiper-wrapper">
            {
              adList && adList.map((item) => (
                <li className='swiper-slide addMediatLi' key={item.id}>
                  <Player
                    playsInline
                    poster={item.img}
                    src={item.url}
                  />
                  {/* <img className='frame' src={setImg('Frame.png')} alt="广告图" /> */}
                </li>
              ))
            }
          </ul>
        </div>
        <div className='twoTitle'>媒体采访</div>
        <div className="swiper-container adSwiper">
          <ul className="swiper-wrapper">
            {
              mediaList && mediaList.map((item) => (
                <li className='swiper-slide addMediatLi' key={item.id}>
                  <Player
                    playsInline
                    poster={item.img}
                    src={item.url}
                  />
                  {/* <img className='frame' src={setImg('Frame.png')} alt="广告图" /> */}
                </li>
              ))
            }
          </ul>
      </div>
    </div>
    );
  }
}
export default withRouter(staticMediaDateBase);