import React from 'react';
import './index.scss';
import imagebs from '../image/group48@2x.jpg';

class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="business">
        <div className="titlebs">
          <h2>商务合作</h2>
          <h3>商务合作联系电话 张吉：13651266152</h3>
        </div>
        <div className="bannerbs">
          <img className="imagebs" src={imagebs} alt="" />
        </div>
      </div>
    );
  }
}

export default Business;
