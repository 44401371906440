import React from 'react';
import './index.scss';

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyList: [
        {
          id: 9,
          time: '2022',
          content: [
            '突破疫情重围，奠定发展基础',
            '数字化、智能化升级，推进运营模式与生态进化',
            '持续孵化自营品牌，覆盖健康、美妆、食品等多品类',
            '助力多个一线中国品牌出海中东北非市场',
          ]
        },
        {
          id: 8,
          time: '2021',
          content: [
            '确立“数字化、智能化、多渠道、多平台媒体零售企业” 全新战略定位；',
            '直播带货、社群新零售、大数据等渠道迅速扩张；',
            '创新商业模式深耕北京市场；',
            '多款自营品牌上线热销；',
            '电视大屏节目迭代升级；',
            '与全聚德等知名品牌战略合作升级；',
            '会员规模突破1200万；',
            '双十一成交破1.5亿；',
            '聚鲨主播获腾讯直播大奖；',
            '助推红旗轿车等广告登陆沙特阿拉伯',
          ]
        },
        {
          id: 7,
          time: '2020',
          content: [
            '电商占比超30%，居行业首位；',
            '启动腾讯视频直播、抖音直播；',
            '启动社群新零售渠道；',
            '北京IPTV独家战略合作；',
            '多个自营品牌上市；',
            '独家承制北京网络直播总决赛；',
            '节目《鲨鱼对决秀》揽获多奖项；',
            '天津供应链管理与配送中心奠基；',
            '双11成交破2亿'
          ]
        },
        {
          id: 6,
          time: '2019',
          content: [
            '大数据营销渠道实现100%增长；',
            '启动自营品牌战略；',
            '中国大陆地区会员破800万；',
            '成立聚鲨学院 升级人才培养机制；',
            '双11成交破1.5亿'
          ],
        },
        {
          id: 5,
          time: '2018',
          content: [
            '品牌升级“聚鲨环球精选”；',
            '与中东媒体集团MBC达成战略合作；',
            '参展首届中国国际进口博览会；',
            '成立大数据营销南方中心；',
            '环球云上线，实现系统自主研发；',
            '双11成交破1亿',
          ]
        },
        {
          id: 4,
          time: '2017',
          content: [
            '收购CITRUSS 进军中东北非市场；',
            '与泰国最大媒体零售企业TVD达成战略合作',
          ]
        },
        {
          id: 3,
          time: '2016',
          content: [
            '推出国内首个移动视频App；',
            '与韩国公营购物达成战略合作；',
            '开辟大数据营销渠道',
          ]
        },
        {
          id: 2,
          time: '2015',
          content: [
            '覆盖上海，打破重点市场垄断；',
            '跨境电商业务上线；',
            '韩国首尔召开招商大会；',
            '业内首家布局IPTV／OTT领域；',
            '双11成交破1500万 ',
          ]
        },
        {
          id: 1,
          time: '2014',
          content: [
            '实现中国北方区域全覆盖；',
            '北京位序调至歌华19；',
            '召开首届供应商代表大会',
          ]
        },
        {
          id: 0,
          time: '2013',
          content: ['频道改版全新开播'],
        },
      ],
      dotActiveSign: 0
    };
  }

  componentDidMount() {

  }

  setDotActiveSign (dotActiveSign) {
    this.setState({
      dotActiveSign
    }, () => {
      setTimeout(() => {
        document.getElementsByClassName('year-dot')[dotActiveSign].scrollIntoViewIfNeeded({behavior: 'smooth', inline: 'center', block: 'center'});
      })
    })
  }

  // 左右切换
  prevOrNextChange(type) {
    if (type === 'prev') {
      if (this.state.dotActiveSign > 0) this.setDotActiveSign(this.state.dotActiveSign - 1)
      else this.setDotActiveSign(0)
    } else {
      const len = this.state.historyList.length - 1
      if (this.state.dotActiveSign < len) this.setDotActiveSign(this.state.dotActiveSign + 1)
      else this.setDotActiveSign(len)
    }
  }

  getCardRightContent() {
    return this.state.historyList[this.state.dotActiveSign].content.map((h, i) => <li key={i}>{h}</li>)
  }

  render () {
    return (
      <div className="history-card">
        <div className="history-content">
          <div className="history-content-left">
            <span>{this.state.historyList[this.state.dotActiveSign].time}</span>
            <span>年</span>
          </div>
          <ul className="history-content-right">
            {this.getCardRightContent()}
          </ul>
        </div>
        <div className='history-box'>
            <div className="history-action">
              <div 
                className="history-action-prev"
                onClick={() => {
                  this.prevOrNextChange('prev')
                }} 
              />
              {(this.state.historyList || []).map((h, i) => 
                  <span 
                    className={`year-dot ${i === this.state.dotActiveSign ? 'dot-active' : ''}`} 
                    key={i}
                    onClick={() => {
                      this.setDotActiveSign(i)
                    }}
                  >
                    {h.time}年
                  </span>
                )
              }  
              <div 
                className="history-action-next"
                onClick={() => {
                  this.prevOrNextChange('next')
                }} 
              />
            </div>
     
        </div>
       </div>
    )
  }
}

export default History