import React from 'react';
import { withRouter } from 'react-router-dom';
import '@/assets/css/swiper.css';
import style from './chineseVersion.module.scss';

class staticChineseVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {}

  render() {
    return (
      <div className={style.container}>
        <div className={style.title}>简介</div>
        <p className={style.indent}>
        聚鲨环球精选（SHARK SHOPPING），国际化的全媒体零售平台。
以精选模式引领零售趋势。由专业买手秉承严苛精选机制，深入原产国、原产地寻找当地“好货”，旨在提供源自最佳产地的国内外一线品牌尤其是进口和独家销售商品。我们为顾客的生活精挑细选，让顾客远离无序的海量，让选择更简单且更有品质。不再浪费时间淘，聚鲨为你精选好，就是我们的衷心承诺。
        作为视频购物领导者，我们致力于为消费者提供超乎传统电子商务和实体店之外的“第三种购物方式”——精选全球一流的优质商品，通过有线电视、智能大屏、电商APP、大数据营销、微信社群以及第三方媒体电商平台，为数以亿万计的家庭提供优质商品和服务，为全球3亿家庭创造理想生活。
我们结合媒体、零售和社交优势，借力数字技术和媒体科技，驱动零售渠道的非凡创新和优化，为商家、品牌及其他企业赋能，让其可以借助视频购物的力量实现增长。我们先后投资控股中东北非地区最大的媒体零售企业CITRUSS，开发沙特市场，并在黎巴嫩贝鲁特自建呼叫中心，在香港建立跨境电商服务平台等，持续参与零售全球化进程，同时推动中国零售需求侧与全球品牌供给侧之间的消费升级。
聚鲨环球精选总部位于中国北京，在中国香港、阿联酋迪拜、沙特利雅得、黎巴嫩贝鲁特等地设有分子公司。全球团队成员来自23个国家和地区，总人数近1800名。聚鲨环球精选是中国国家高新技术企业、中国电视购物联盟理事单位及中国电子商会副会长单位。聚鲨环球精选自主研发并拥有全部知识产权的聚鲨环球云系统，处于全国领先水平，在中国电视购物行业属首家。
{/* 聚鲨环球精选已通过国际认证机构BSI的质量管理体系（ISO9001:2015）再度认证和ISO27001信息安全管理体系认证。 */}
        </p>

        <div className={style.title}>创始人简介</div>
        <p className={style.indent}>
        孔炯（庆华），1975年生，祖籍山东曲阜，孔子第七十三代孙。中国知名企业家、传媒届领军人物及资深学者。中国文化名家暨“四个一批”人才，高级编辑、高级经济师、高级工程师。全球电子零售协会(ERA-Global)全球董事、孔子世家谱常态化续修委员会副会长、至圣孔子基金会执行理事。悦家繁荣集团(EverFlourish Group)公司创始人兼董事长，聚鲨环球精选（SharkShopping）创始人兼董事长，中东北非最大的媒体零售集团CITRUSS公司董事长。
        </p>
        <p className={style.indent}>
        毕业于东南大学、长江商学院EMBA。曾任贵州省电视台副台长、华人文化产业投资基金（CMC）董事总经理等职。
        </p>
        <p className={style.indent}>
        突出荣誉：中宣部文化名家暨“四个一批”人才、全国青联委员、全国先进工作者、全国公益广告先进个人、中国广告年度突出贡献人物、十大传媒特殊贡献人物、影响中国广告业年度人物、中国最具影响力品牌电视媒体领军人物等。
        </p>
        <p className={style.indent}>
        学术专著：著有《传媒赢利模式》一书，首次提出“渠道产品模式”，建立了传媒产业竞争的模式理论。被业内认为首次揭示媒体经营成败的关键。
        </p>
        
        <div className={style.title}>精选模式引领零售趋势</div>
        <p><span className={style.titleSpan}>•</span>
          专业买手深入原产国、原产地寻找当地“好货”
        </p>
        <p><span className={style.titleSpan}>•</span>
          进口国际大品牌商品，每一件都值得购买
        </p>
        <p><span className={style.titleSpan}>•</span>
          精选优秀高品质商品，每一层都严格把关
        </p>
        <div className={style.title}>媒体零售领先者，为全球3亿家庭创造理想生活</div>
        <p><span className={style.titleSpan}>•</span>
          在中国大陆地区，聚鲨环球精选覆盖2亿户家庭，8亿观众。其中高标清同步播出频道触达1.7亿户家庭；IPTV触达1.1亿户家庭；OTT智能终端超过2.8亿台，   亿万用户可随时观看直播或随心点播回看
        </p>
        <p><span className={style.titleSpan}>•</span>
          在中东北非地区，聚鲨环球精选子公司CITRUSS覆盖超过1亿户家庭，4.5亿人口
        </p>
        <p><span className={style.titleSpan}>•</span>
          通过TV大屏、移动APP、第三方网络直播平台等渠道，每天发布100+小时的直播内容
        </p>
        <div className={style.title}>大量热忱顾客聚集在这里</div>
        <p><span className={style.titleSpan}>•</span>
        近千万忠诚会员
        </p>
        <p><span className={style.titleSpan}>•</span>
        累计每天超过10万人次与我们深度互动，获取订购及服务支持
        </p>
        <div className={style.title}>为商家、品牌及其他企业赋能</div>
        <p><span className={style.titleSpan}>•</span>
        “双创”品牌孵化器和落地平台，助力中小企业成长
        </p>
        <p><span className={style.titleSpan}>•</span>
        良币驱逐劣币，推动“中国制造”良性发展
        </p>
        <div className={style.title}>国际化战略</div>
        <p className={style.indent}>        2017年9月20日，聚鲨环球精选投资控股CITRUSS沿着“一带一路”走出去，是中国媒体零售行业的首次出海。
CITRUSS购物频道通过尼罗河卫星和阿拉伯通讯卫星播出，同时在阿联酋通过Etisalat E-Life和Du两家有线电视网络及OSN付费电视网络、在沙特阿拉伯通过STC网络平台播出，累计覆盖中东北非地区超过1亿户家庭，总辐射人口达4.5亿。经过十几年的发展，CITRUSS已成为一家开放的国际化公司，员工由来自中国、法国、比利时、德国、印度、伊朗、伊拉克、科威特、沙特阿拉伯、巴林、卡塔尔、阿联酋、阿曼等23个国家构成。
         于沙特利雅得设有ATT公司，致力于开发沙特市场。由CITRUSS提供节目覆盖和商品来源，ATT提供进口和分发渠道，协助CITRUSS在沙特的后端运营,持续为消费者提供支持与服务。
         Keyone 公司是CITRUSS在黎巴嫩贝鲁特自建的呼叫中心，于2018年7月15日正式独立运营。目前，呼叫中心面积总计400平方米，拥有近200名员工，拥有完善的IT系统支持，可同时进行接线、外呼、WhatsApp服务、售后服务等多项业务功能，工作语言涉及阿拉伯语，英文，法语等。
此外，CITRUSS还在沙特阿拉伯、阿联酋、阿曼、科威特及巴林分别有现代化仓储中心。
2018年以来，CITRUSS进入历史性快速发展阶段，与MBC集团签订了战略合作协议，获取了黄金频道位序和其他频道播出资源等，使CITRUSS的销售规模和盈利能力显著增长。
         MBC集团是中东北非地区最具影响力的媒体集团，是中东北非市场娱乐、新闻、电影等频道内容的市场领导者，覆盖中东北非地区86%以上的卫星电视家庭，有1.34亿阿拉伯家庭收看MBC集团旗下电视频道。
         聚鲨环球精选携手CITRUSS，致力于通过与MBC等战略合作伙伴的共同努力，推动中国与中东北非地区的商品互通与文化互动。
未来，我们将继续推进国际化战略，将聚鲨环球精选打造成“一带一路”沿线重要的商业零售渠道和文化传播平台，推动中国市场与海外市场的商品双向流通，为促进一带一路沿线国家文化交流，提升中国文化的综合影响力做出贡献。</p>
        <div className={style.title}>企业文化</div>
        <p><span className={style.titleSpan}>•</span>企业使命</p>
        <p>为全球3亿家庭创造理想生活是我们的使命。不再浪费时间淘，聚鲨为你精选好。我们的专业买手团队深入原产国、原产地寻找当地“好货”，让顾客的选择更简单且更有品质。我们相信，因为我们的努力，每一位顾客、每一个家庭的购物方式和生活习惯正在发生着改变：不仅省下了更多时间，而且拥有了更好的生活品质。</p>
        <p><span className={style.titleSpan}>•</span>企业愿景</p>
        <p>成为受人尊重的伟大企业和全球闻名的媒体零售集团是我们的愿景。我们倡导所有员工、合作伙伴以及所有企业一起行动，共同投身可持续发展的公益事业。我们也始终秉持开放之态，与各个领域的优秀伙伴不断探索多元合作。随着数字技术和媒体科技在零售业的应用，我们正在搭建起一个融合共生的商业生态系统，不仅为全球优秀品牌、中小企业创造商机，也为数以亿计的顾客和家庭带来更加人性化的购物体验。</p>
        <p><span className={style.titleSpan}>•</span>鲨鱼精神</p>
        <p>鲨鱼是聚鲨环球精选的精神图腾。</p>
        <p>作为海洋中最强悍的生物，鲨鱼全身软骨，具备强大的协调能力，能够快速适应各种环境的变化。企业也该如此，只有不断适应环境，才能持续发展壮大。鲨鱼一生要换三万颗牙齿，前排脱落，后排填补，终生持续更替，牙齿即是它最强有力的武器。亦如企业的学习能力，要保证强大的核心竞争力，就必须坚持学习，不断填充完善自身的领先优势。鲨鱼是唯一没有鳔的鱼类，一旦放弃游动就会沉入海底。正如一个优秀的企业，无论多么强大，都必须不断进取，保持自身实力。在实现美好愿景的征途中，我们希望与具备鲨鱼精神的伙伴一起遨游世界。</p>
        <p><span className={style.titleSpan}>•</span>行为特征</p>
        <p>敢为天下之先</p>
        <p>追求卓越之心</p>
        <p>推崇英雄之举</p>
        <p>秉承开放之态</p>
        <p><span className={style.titleSpan}>•</span>聚鲨人才观</p>
        <p style={{marginBottom:'40px'}}>梦想、忠诚、学习、行动</p>
      </div>
    );
  }
}

export default withRouter(staticChineseVersion);
