import React from 'react';
import setImg from '@/utils/imgs';
import Swiper from 'swiper' 
import './Homespwire.scss' 

class Homespwire extends React.Component {
	constructor(props) {
		super(props);
		var isMobile = false
		if (window.matchMedia("(max-width: 750px)").matches) {
			isMobile = true
		} 

		this.state = { 
		  swiperlist: [
				{
					id: '1',
					images: isMobile ? setImg('Group1.jpg') : setImg('tu12x.png'),
					// icons: setImg('Group33.png'),
					title: '中国第一家国际化全媒体零售平台',
					// subTitle: '中国第一家国际化全媒体零售平台',
					contentList: [
						'中国最年轻、成长速度最快的媒体零售企业',
						'中国第一家响应“一带一路”倡议走出去的国际化媒体零售企业',
						'中国第一家推出移动视频电商平台的媒体零售企业',
						'中国第一家推出高标清同播购物频道的媒体零售企业',
						'中国第一家布局互联网电视、OTT渠道的媒体零售企业',
						'中国第一家拥有自主研发知识产权—聚鲨环球云系统的媒体零售企业'
					]
				},
				{
					id: '2',
					images: isMobile ? setImg('Group2.jpg') : setImg('tu22x.png'),
					// icons: setImg('Group34.png'),
					title: '中国最具创新力的媒体零售企业',
					// subTitle: '中国最具创新力的媒体零售企业',
					contentList: [
						'中国国家高新技术企业',
						'中国电视购物联盟理事单位',
						'中国电子商会副会长单位',
					]
					// '通过国际认证机构BSI的质量管理体系（ISO9001:2015）再度认证、',
					// 'ISO27001信息安全管理体系认证'
				},
			],
			isMobile: isMobile
		};
	}

	componentDidMount() {
		var isMobile = false
		if (window.matchMedia("(max-width: 750px)").matches) {
			isMobile = true
		} 
		new Swiper('.swiperboxs', {
			// 如果需要分页器
			loop: true,
			autoplay: true,
			width: isMobile ? undefined : window.innerWidth,
			height: isMobile ? 250 : window.innerWidth / 750 * 500,
      pagination: {
        el: '.homeimages-swiper-pagination',
        clickable: true,
        renderBullet(index, className) {
          const str = `<span class="point ${className}"></span>`;
          return str;
        },
      },
			on: {
				resize: function(){
					this.params.width = window.innerWidth;
					this.params.height =  window.innerWidth / 1440 * 640

					if (isMobile) {
						this.params.width = undefined;
						this.params.height =  window.innerWidth / 750 * 500
					}
					this.update();
				},
		 } ,
		});
	}

  render() {
	  const { swiperlist } = this.state
    return (
			<div className='boxwidth'>
					<div className="swiperboxs">
						<div className="swiper-wrapper">
							{
								swiperlist.map((item,inde) => {
									return (
										<div className="swiper-slide" key={inde}>
											<img className='homeimages' src={item.images} />

											<div className='swiper-content'>
												{/* <img className='swiper-content-prev' src={setImg('Group33.png')} /> */}
												<h3 className='swiper-content-title'>{item.title}</h3>
												<p className='swiper-content-sub-title'>{item.subTitle}</p>
												<ul className='swiper-content-ul'>
													{item.contentList.map((c, ci) => <li key={ci}>{c}</li>)}
												</ul>
												{/* <img className='swiper-content-next' src={setImg('Group34.png')} /> */}
											</div>
										</div>
									)
								})
							}
						</div>
					<div className="homeimages-swiper-pagination"></div>
        </div>
			</div>
    )
  }
}


export default Homespwire;