import React from 'react';
import setImg from '@/utils/imgs';
import './index.scss';
import Table from './components/Table.jsx';
import History from './components/history/index';
import HomeVideo from './components/HomeVideo/index';
import HomeSwiper from './components/Homespwire/Homespwire.jsx'
// import path from 'https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100765/0124110144_yasuo.mp4'
import { Player } from 'video-react';

class StaticHome extends React.Component {
  constructor(props) {
    super(props);
    var isMobile = window.matchMedia("(max-width: 750px)").matches
    this.state = { 
      fiamilylist: [
        {
          id: 1,
          title: '3亿家庭',
          conter: '中国大陆2亿户家庭',
          conter1: '中东北非1亿户家庭'
        },
        {
          id: 2,
          title: '1400万+会员',
          conter: '中国大陆1200万会员 ',
          conter1: '中东北非200万会员'
        },
        {
          id: 3,
          title: '60%+进口品牌 ',
          conter: 'GoodHealth、COACH',
          conter1 :'Dyson、BOSS、LOVO等',
        },
        {
          id: 4,
          title: '多个大型演播厅',
          conter: '北京3个演播厅+10余个5G演播间 ' ,
          conter1: '阿联酋迪拜2个演播厅'
        },
        {
          id: 5,
          title: '1000+大数据坐席',
          conter: '中国北京、燕郊、长沙700+坐席 ',
          conter1: '黎巴嫩贝鲁特200+坐席',
        },
        {
          id: 6,
          title: '多地物流仓储中心',
          conter: '北京、天津、长沙、昆山 ',
          conter1: ' 沙特阿拉伯、阿联酋 ',
          conter2: '阿曼、科威特、巴林'
        },
      ],
      platformlist: [
        {
          id: 1,
          title: '聚鲨环球精选 媒体平台',
          subtitle: 'Media Platform @Shark Shopping',
          imglist: [
            {
              ids: 1,
              icon: setImg('MaskGroup.png'),
              link: 'https://a.app.qq.com/o/simple.jsp?pkgname=net.ghs.app',
            },
            {
              ids: 2,
              icon: setImg('weixin.png'),
              link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI0ODg2NTg2OQ==&scene=124#wechat_redirect',
            },
            {
              ids: 3,
              icon: setImg('douyin.png'),
              link: 'https://v.douyin.com/eYw5sBA/',
            },
            {
              ids: 4,
              icon: setImg('weibo.png'),
              link: 'https://weibo.com/u/6523206065',
            },
          ],
        },
        {
          id: 2,
          title: '海外事业 CITRUSS 媒体平台',
          subtitle: 'Overseas Business / Media Platform @Citruss',
          imglist: [
            {
              ids: 1,
              icon: setImg('citruss.png'),
              link: 'https://appapi.citruss.com/HTML5/CitrussAppDownload.html',
            },
            {
              ids: 2,
              icon: setImg('phone.png'),
              link: 'https://www.instagram.com/citrusstv/',
            },
            {
              ids: 3,
              icon: setImg('yazi.png'),
              link: 'https://www.snapchat.com/add/citrusstv.com',
            },
            {
              ids: 4,
              icon: setImg('douyin.png'),
              link: 'https://www.tiktok.com/@citrusstv?lang=en',
            },
            {
              ids: 5,
              icon: setImg('dingding.png'),
              link: 'https://twitter.com/citrusstv?s=11',
            },
            {
              ids: 6,
              icon: setImg('f.png'),
              link: 'https://www.facebook.com/citrusstv',
            },
            {
              ids: 7,
              icon: setImg('youtube.png'),
              link: 'https://www.youtube.com/user/CitrussTV',
            },
          ],
        },
        {
          id: 3,
          title: '聚鲨环球精选全球成员机构',
          subtitle: 'SHARK SHOPPING Global Branches ',
          subtitle2: '北京 ・ 香港 ・ 迪拜 ・ 利雅得 ・ 贝鲁特',
          subtitle3: 'Beijing ・ Hong Kong ・ Dubai ・ Riyadh ・ Beirut',
          imglist: [],
        },
      ],

      isShowVideo: false,

      isMobile
    };
  }

  onVidio (){
    this.setState({
      isShowVideo: true
    })
  }

  onPlayVideo() {
    document.getElementById('outer-play-video').play()
  }

  closeVideo () {
    this.setState({
      isShowVideo: false
    })
  }

  render() {
    const { fiamilylist, platformlist } = this.state;
    return (
      <div className='mobe'>
        
            <div className='biaot'><img className='imgs'  src={setImg('logoslogx.png')} /></div>

          <div className='vidio' > 
                <Player
                  id="outer-play-video"
                  autoPlay
                  poster={setImg('compine.jpg')}
                  playsInline
                  muted
                  src="https://ghs-mall-img.oss-cn-beijing.aliyuncs.com/oss/xin_prod/file/100765/82181927c075410b48e4fda07c2ed314.mp4"
                  onClick={() => this.onPlayVideo()} 
                  disableDefaultControls={true}
                  loop
                  controls={false}
                />

            {/* {
              !this.state.isMobile
                ? <>
                  <video id="outer-play-video" src="https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100765/0124110144_yasuo.mp4" onClick={() => this.onPlayVideo()} preload="metadata" muted autoPlay controls={this.state.isMobile} className="tk_video vidio"></video>
                </>
                : <Player
                  poster={setImg('compine.jpg')}
                  playsInline
                  muted
                  src="https://ghs-mall-img.oss-cn-beijing.aliyuncs.com//oss/xin_prod/file/100765/0124110144_yasuo.mp4"
                />
            } */}
            <img className='anniu' src={setImg('Group16.png')} onClick={() => this.onVidio()} />
            <div className='mengceng'></div>
          </div>
          <HomeVideo mask={this.state.isShowVideo} onClose={() => this.closeVideo()}></HomeVideo>

              <video className='minvidio' controls="controls" poster={setImg('compine.jpg')} src="https://ghs-mall-img.oss-cn-beijing.aliyuncs.com/oss/xin_prod/file/100765/82181927c075410b48e4fda07c2ed314.mp4" />
              <div className='beijing-bottom'>
              <div className='titles'>
                <span>我们是谁</span>
              </div> 
              <div className='conner'>
                  聚鲨环球精选总部位于中国北京，在中国香港、阿联酋迪拜、沙特利雅得、黎巴嫩贝鲁特等地设有分子公司，团队来自23个国家和地区，员工总数近1800名。 <br/><br/>
                  通过有线电视、智能大屏、电商App、大数据营销、微信社群以及第三方媒体电商平台，聚鲨环球精选为全球3亿家庭提供优质的商品服务，创造理想的生活体验。<br/><br/>
                  聚鲨环球精选持续参与零售全球化进程，并推动中国零售需求侧与全球品牌供给侧之间的消费升级。
              </div>
              <img className='imgstwo' src={setImg('Group1581.png')} />
              </div>

          <div className='titles'>
            <span>我们在做什么</span>
          </div>
          <Table></Table>

        <div className='beijing'>
            <div className='boxfiamily'>
              {
                fiamilylist.map( (item, index) => {
                  return (
                      <div key={index} className='fiamily'>
                        <h3>{item.title}</h3>
                        <span>{item.conter}</span><br/>
                        <span>{item.conter1}</span><br/>
                        <span>{item.conter2}</span><br/>
                      </div>
                    )
                })
              }
            </div>
        </div> 

          <div className='titles'>
            <span>发展历程</span>
          </div>
          <br /><br />
          <History />

        <HomeSwiper />

        <div className='backgro'>
          <div className='titles'>
            <span>鲨鱼精神</span>
          </div>
            <div className='conner'>作为海洋中最强悍的生物，鲨鱼全身软骨，具备强大的协调能力，能够快速适应各种环境的变化。企业也该如此，只有不断适应环境，才能持续发展壮大。<br/><br/>
            鲨鱼一生要换三万颗牙齿，前排脱落，后排填补，终生持续更替，牙齿即是它最强有力的武器。亦如企业的学习能力，要保证强大的核心竞争力，就必须坚持学习，不断填充完善自身的领先优势。<br/><br/>
            鲨鱼是唯一没有鳔的鱼类，一旦放弃游动就会沉入海底。正如一个优秀的企业，无论多么强大，都必须不断进取，保持自身实力。<br/><br/>
            在实现美好愿景的征途中，我们希望与具备鲨鱼精神的伙伴一起遨游世界。</div>
            <img className='imgs' src={setImg('Group1591.jpg')} />
          </div>
          <div className='need-bottom'>
            {
              platformlist.map( (item, index) => {
                return (
                    <div className="footer-title-module" key={index}>
                      <div className='titlecenter'>{item.title}</div>
                      
                      {item.subtitle && <div className='titlecentericon'>{item.subtitle}</div>}
                      {item.subtitle2 && <div className='titlecentericon'>{item.subtitle2}</div>}
                      {item.subtitle3 && <div className='titlecentericon'>{item.subtitle3}</div>}
                      <div className='boxicons'>
                        {
                          item.imglist.map( (item, ids) => {
                            return (
                                <div className='boxicon' key={ids}>
                                  <a className='pc-logos' href={item.link}>
                                     <img className='pc-logos' src={item.icon} />
                                  </a>
                                  <img className='logos' src={item.icon} />
                                </div>
                            )
                          })
                        }
                      </div>
                      
                    </div>
                  )
              })
            }
          </div>
      </div>
    );
  }
}

export default StaticHome
