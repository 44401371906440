import React, { useState, useEffect, useRef } from 'react';
import { 
  Player } from 'video-react';
import setImg from '@/utils/imgs';
import './index.scss' 

function HomeVideo ({
  mask = false,
  onClose = () => {}
}) {
  const [showMask, setShowMask] = useState(mask)
  const [playerRef, setPlayerRef] = useState(mask)

  function play() {
    playerRef?.seek(0)
    playerRef?.play()
  }

  function pause() {
    playerRef?.pause()
    onClose()
  }

  useEffect(() => {
    setShowMask(mask)
    if (mask) setTimeout(() => play(), 100)
  }, [mask])

  return (
    <div className={`vidiobox ${showMask ? 'video-show-play' : ''}`}>
      <div className='videobox-inner' style={{ background: `url(${setImg('compine.png')}) center no-repeat`, backgroundSize: '100%' }}>
        <Player 
          ref={ref => setPlayerRef(ref)}
          playsInline
          src="https://ghs-mall-img.oss-cn-beijing.aliyuncs.com/oss/xin_prod/file/100765/82181927c075410b48e4fda07c2ed314.mp4" 
          autoplay
          preload="metadata">
        </Player>
        <img className='closes' onClick={() => pause()} src={setImg('close.png')} />
      </div>
    </div>
  )
}

export default HomeVideo;
