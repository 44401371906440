import React from 'react';
import './index.scss';
import imagebs from '../image/group49@2x.png';
import zhuangshi from '../image/zhuangshi@2x.png';
import group50 from '../image/group50@2x.png';
import group52 from '../image/group52@2x.png';
import group54 from '../image/group54@2x.png';
import group53 from '../image/group53@2x.png';
import group51 from '../image/group51@2x.png';

class Responsibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="responsibility">
        <div className="titlerlt">
          <img className="zhuangshi" src={zhuangshi} alt="" />
          <h2>2020 践行社会责任，助力抗击新冠疫情</h2>
          <p>2020年，新型冠状病毒疫情给全人类带来灾难和严峻考验。聚鲨环球精选认真贯彻落实习近平总书记重要指示精神,全面落实中央和
          总台关于疫情防控的决策部署，在参与抗击疫情的同时，积极采取措施稳定经营、开拓市场，尽力快速恢复生产，保障疫情期间民
          生供应。同时，积极承担媒体企业社会责任，参与抗疫公益活动。</p>
        </div>
        <div className="connerlt">
          <img className="imagebs" src={imagebs} alt="" />
          <img className="imagebs" src={group50} alt="" />
          <img className="imagebs" src={group51} alt="" />
        </div>
        <div className="centerlt">
          <h3>向边远地区捐赠抗疫药物图片</h3>
        </div>

        <div className="titlerlt">
          <img className="zhuangshi" src={zhuangshi} alt="" />
          <h2>2019 聚鲨环球精选组织寒冬送温暖公益活动</h2>
          <p>为了给贫困山区的孩子和他们的父母们送去温暖，2019年12月，聚鲨环球精选党支部携手公司柠檬鲨社团活动策划部，举办2019
          年&apos;捐冬衣、献爱心&apos;公益捐赠活动。 聚鲨员工们共计捐赠棉衣裤、毛衣、羽绒服、呢大衣等防寒性较强的衣物、鞋子等一百余件，
          生供应。同时，积极承担媒体企业社会责任，参与抗疫公益活动。</p>
        </div>
        <div className="connerlt">
          <img className="imagebs" src={group52} alt="" />
          <img className="imagebs" src={group53} alt="" />
          <img className="imagebs" src={group54} alt="" />
        </div>
        <div className="centerlt">
          <h3>寒冬送温暖公益活动</h3>
        </div>
      </div>
    );
  }
}

export default Responsibility;
